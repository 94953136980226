import { Box } from "@mui/system";
import { Outlet } from "react-router-dom";
import { Nav } from "./Nav";
import { Container, useTheme } from "@mui/material";

function ProfileLandingScreen() {
  const theme = useTheme();
  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          "& nav": {
            display: "none",
          },
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "250px 1fr",
            "& nav": {
              display: "block !important",
            },
          },
        }}
      >
        <Box component={"nav"}>
          <Nav />
        </Box>
        <div>
          <Outlet />
        </div>
      </Box>
    </Container>
  );
}

export default ProfileLandingScreen;
