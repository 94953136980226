import React from "react";
import { useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { FormButton, FormInput, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ContactSupportModel, useContactSupport } from "../../../services/general";
declare var grecaptcha;
const schema = yup
  .object<YupShape<ContactSupportModel>>({
    email: yup.string().required().email(),
    firstName: yup.string().required(),
    phone: yup.string().required(),
    lastName: yup.string().required(),
    message: yup.string().required(),
  })
  .required();
function FormSection() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ContactSupportModel>({
    resolver: yupResolver(schema),
  });
  const { mutate: submit, isLoading } = useContactSupport();
  const submitForm = (model: ContactSupportModel) => {
    submit(model, {
      onSuccess: () => {
        toast.success("Message sent successfully.");
        navigate("/");
      },
    });
  };
  return (
    <Box
      style={{
        background: "linear-gradient(180deg, rgba(255, 254, 251, 0.01) 0%, rgba(255, 254, 251, 0.13) 100%)",
        backdropFilter: "blur(1.25rem)",
        borderRadius: "1.25rem",
        border: "0.125rem solid #353439",
      }}
      component="form"
      onSubmit={handleSubmit(submitForm)}
    >
      <div
        style={{
          padding: "2.375rem 2.125rem 1.25rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <Box
          component={"img"}
          src="/assets/images/customer-agent.png"
          sx={{
            width: "4.8125rem",
            height: "4.8125rem",
            borderRadius: "50vh",
          }}
        />
        <div>
          <Typography sx={{ fontSize: "1.5rem", lineHeight: "1.875rem" }}>Hello, I'm Jennifer</Typography>
          <Typography sx={{ lineHeight: "1.25rem", mt: "0.5rem" }}>Please fill the form below and I'll get back to you in a jiffy 😀 with the next steps</Typography>
        </div>
      </div>
      <hr style={{ borderColor: "#2F2E32" }} />
      <div style={{ padding: "2.125rem" }}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
            <FormInput required label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="Email Address" {...register("email")} helperText={errors.email?.message} />
            <FormInput required label="Phone" {...register("phone")} helperText={errors.phone?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="Message" {...register("message")} size={"medium"} multiline helperText={errors.message?.message} />
          </Stack>

          <FormButton type="submit" label="Submit" fullWidth loading={isLoading} disabled={!isValid} />
        </Stack>
      </div>
    </Box>
  );
}

export default FormSection;
