import { Box } from "@mui/material";
import { useGetGiftCardById } from "../../../../services/gift";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
import { FaceLabel } from "./FaceLabel";

export const BackView = () => {
  const { model } = useCartDeliveryContext();
  const { data: gift } = useGetGiftCardById(model?.giftId);
  return (
    <Box sx={{}}>
      <div style={{ overflow: "hidden", borderRadius: "12px" }}>
        <img
          src="/assets/images/gift-back.png"
          alt=""
          style={{
            borderRadius: "12px",
            maxHeight: "180px",
            height: "180px",
          }}
        />
      </div>

      <FaceLabel text={"back"} />
    </Box>
  );
};
