import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormButton, FormInput, PageHeaderTitle, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { CheckVoucherBlanceModel, VoucherSwapModel, useCheckBalanceVoucher } from "../../../../services/voucher";
import { VoucherInfoView } from "../../voucher/voucher-info/VoucherInfoView";
import Card from "@mui/material/Card";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const schema = yup
  .object<YupShape<CheckVoucherBlanceModel>>({
    voucherCode: yup.string().required(),
    pin: yup.string().required(),
  })
  .required();
const CheckBalanceScreen = () => {
  const { code } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<CheckVoucherBlanceModel>({
    defaultValues: {
      voucherCode: "",
      pin: "",
    },
    resolver: yupResolver(schema),
  });
  useDocumentTitle("Check Balance");
  const { isLoading, mutate: checkBalance, data } = useCheckBalanceVoucher();
  const submitForm = (model) => {
    checkBalance(model);
  };
  return (
    <Card
      sx={{
        borderRadius: "30px",
        backgroundColor: "primary.main",
        padding: "40px 50px",
        gap: "20px",
        display: "grid",
      }}
    >
      <PageHeaderTitle title="Check Voucher Balance & History" sx={{ marginBottom: "30px" }} />
      {!data ? (
        <Stack spacing={3} sx={{}}>
          <Stack spacing={2} direction="row">
            <FormInput charLength={16} value={watch("voucherCode")} isNumber label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
            <FormInput value={watch("pin")} label="Security Code" {...register("pin")} type="password" isNumber charLength={6} helperText={errors.pin?.message} />
          </Stack>
          <FormButton label="Check" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{ alignSelf: "flex-start" }} />
        </Stack>
      ) : (
        <VoucherInfoView data={data} />
      )}
    </Card>
  );
};

export default CheckBalanceScreen;
