import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { FormButton, FormInput, FormSelect, NameAndId, PageHeaderTitle, YupShape, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { VoucherSwapModel, useSwapVoucher } from "../../../../services/voucher";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { VoucherInfoView } from "../VoucherInfoView";
import { useGetSwapMerchant } from "../../../../services/merchant";
import VoucherServiceCard from "../VoucherServiceCard";
import { useVoucherServiceContext } from "../VoucherServiceContext";
const schema = yup
  .object<YupShape<VoucherSwapModel>>({
    voucherCode: yup.string().required(),
    merchantId: yup.string().required(),
  })
  .required();

function VoucherSwapScreen() {
  const [searchParms, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { message } = useNotificationContext();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<VoucherSwapModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      voucherCode: searchParms.get("voucherCode") || "",
      merchantId: "",
      agreed: false,
    },
  });
  const { voucher } = useVoucherServiceContext();
  useDocumentTitle("Swap Voucher");
  const { data: merchantNames } = useGetSwapMerchant(voucher?.giftId);

  const { isLoading, mutate: swap } = useSwapVoucher();
  const agreeMessage = useMemo(() => {
    if (!voucher?.giftId || !watch("merchantId") || !merchantNames) {
      return null;
    }

    var merchants = merchantNames.filter((x) => x.id == watch("merchantId"));
    if (merchants.length == 0) return null;
    var merchant = merchants[0];
    return `I agree that swapping this voucher will result in a ${merchant.swapRate}% deduction of my voucher value`;
  }, [watch("merchantId"), merchantNames, voucher]);

  const submitForm = (model: VoucherSwapModel) => {
    if (!model.agreed) {
      toast.info("You must agree to the reactivation terms");
      return;
    }
    swap(model, {
      onSuccess: () => {
        message({
          buttonText: "Close",
          title: "Voucher Sent to mail",
          desc: "You have successfully sent voucher to mail",
          onClose: () => {
            navigate(`/vouchers/${model.voucherCode}`);
          },
        });
      },
    });
  };

  useEffect(() => {
    setSearchParams({ voucherCode: watch("voucherCode") });
  }, [watch("voucherCode")]);
  return (
    <div style={{ display: "grid", gap: "30px" }}>
      <VoucherInfoView />
      <VoucherServiceCard title={"Swap Voucher"}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <Controller
              control={control}
              name="voucherCode"
              render={({ field }) => {
                return <FormInput label="Voucher Code" {...field} helperText={errors.voucherCode?.message} />;
              }}
            />
            <Controller control={control} name="merchantId" render={({ field }) => <FormSelect options={merchantNames} label="Preferred Merhcant" {...field} />} />
          </Stack>
          {agreeMessage && (
            <Controller control={control} name="agreed" render={({ field: { onChange, value } }) => <FormControlLabel checked={value} onChange={(e: any) => onChange(e.target.checked)} control={<Checkbox color="success" />} label={agreeMessage} />} />
          )}
          <FormButton label="Swap" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{ alignSelf: "flex-start" }} />
        </Stack>
      </VoucherServiceCard>
    </div>
  );
}

export default VoucherSwapScreen;
