import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { Container, useTheme } from "@mui/material";
import {
  BillCard,
  BillerFilter,
  CustomSearchInput,
  LoadingBox,
  PageHeaderTitle,
  Pagination,
  useDocumentTitle,
  useFilter,
  useGetBillCategories,
  useGetBillCountries,
  useGetBillers,
} from "suregifts-component-lib";

import { NavTitle } from "../../airtime/list/AirtimeListScreen";
import { useGlobalContext } from "../../../../contexts/GlobalContext";
import { NavItem } from "./NavItem";

function BillListScreen() {
  useDocumentTitle("Bills Payment");
  const theme = useTheme();
  const { initialSetting } = useGlobalContext();
  const params = useMemo(() => {
    let countryCode: any = undefined;
    if (initialSetting?.countryCode === "NG" || initialSetting?.countryCode === "KE") {
      countryCode = initialSetting.countryCode;
    }
    return { countryCode: countryCode, page: 1, size: 12 } as BillerFilter;
  }, []);
  const [filter, setFilter] = useFilter<BillerFilter>(params);
  const { data: categories, isLoading } = useGetBillCategories(filter.countryCode, (data) => {});
  const { data: countries } = useGetBillCountries();
  const { data: data, isFetching } = useGetBillers(filter);
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <Container sx={{ marginY: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          "& nav": {
            display: "none",
          },
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "250px 1fr",
            "& nav": {
              display: "block !important",
            },
          },
        }}
      >
        <Box component={"nav"}>
          <PageHeaderTitle title="Bill Payment" />
          <div style={{ display: "grid", gap: "40px", marginTop: "30px" }}>
            <div>
              <NavTitle title={"Countries"} />
              <div style={{ display: "grid", gap: "20px" }}>
                {countries?.map((item) => (
                  <NavItem
                    key={item.id}
                    name={item.name}
                    onClick={() => handleFilter({ countryCode: item.id, categoryId: null, page: 1 })}
                    selected={filter.countryCode === item.id}
                  />
                ))}
              </div>
            </div>
            <div>
              <NavTitle title={"Search"} />
              <CustomSearchInput
                onChange={(e) => handleFilter({ search: e })}
                sx={{
                  backgroundColor: "primary.main",
                  "& input::placeholder": {
                    color: "text.primary",
                    opacity: 1,
                  },
                }}
                placeholder="Search here... e.g mtn"
              />
            </div>
            <div>
              <NavTitle title={"Categories"} />
              <div style={{ display: "grid", gap: "20px", maxHeight: "300px", overflow: "scroll" }}>
                {categories?.map((item) => (
                  <NavItem
                    key={item.id}
                    name={item.name}
                    onClick={() => handleFilter({ categoryId: item.id, page: 1 })}
                    selected={filter.categoryId === item.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </Box>
        <div>
          <LoadingBox
            loading={isFetching}
            sx={{
              display: "grid",
              gap: "1.25rem",
              [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(3,1fr)",
              },
            }}
          >
            {data?.items.map((item, i) => (
              <BillCard
                categoryName={`${item.categoryName} (${item.countries})`}
                key={item.billerId}
                url={`${item.billerId}`}
                name={item.name}
                logoUrl={item.logoUrl}
              />
            ))}
          </LoadingBox>
          <div style={{ marginTop: "1.25rem" }}>
            <Pagination
              align="center"
              size={filter.size}
              page={filter.page}
              total={data?.total}
              onChange={(page) => {
                handleFilter({ page });
              }}
            />
          </div>
        </div>
      </Box>
    </Container>
  );
}

export default BillListScreen;
