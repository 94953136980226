import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { CustomSearchInput, FilterDropdown, FilterElement, SearchIcon } from "suregifts-component-lib";

import { useGetCountries, useGetCategories } from "../../services/gift";
import Card from "@mui/material/Card";

function FilterSection({ handleFilter, filter }) {
  const { data: countryNames } = useGetCountries();
  const { data: categoryNames } = useGetCategories();
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        padding: "24px 43px",
        marginTop: "40px",
        borderRadius: "30px",
        display: "grid",
        gap: "24px",
        "& button.MuiButton-root.MuiButton-containedPrimary": {
          bgcolor: "primary.dark",
        },
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(2,1fr)",
        },
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "repeat(4,1fr)",
        },
      }}
    >
      <FilterElement
        title={"Select Country"}
        render={() => (
          <FilterDropdown
            fullWidth
            display={"Country"}
            onChange={(index, value) => handleFilter({ countryCode: value, page: 1 })}
            value={filter.countryCode}
            options={[{ text: "All" }, ...(countryNames?.map((x) => ({ text: x.name, value: x.code })) || [])]}
            variant="contained"
            color="primary"
            colorLevel="dark"
          />
        )}
      />

      <FilterElement
        title={"Sort by"}
        render={() => (
          <FilterDropdown
            fullWidth
            value={filter.orderBy}
            onChange={(index, value) => handleFilter({ orderBy: value, page: 1 })}
            options={[{ text: "Default" }, { text: "A-Z", value: "ATOZ" }, { text: "Z-A", value: "ZTOA" }, { text: "Most Popular", value: "MOST" }, { text: "Newest", value: "NEWEST" }]}
            display={"Sort by"}
            variant="contained"
            color="primary"
            colorLevel="dark"
          />
        )}
      />
      <FilterElement
        title={"Select category"}
        render={() => (
          <FilterDropdown
            onChange={(index, value) => handleFilter({ categoryId: value, page: 1 })}
            fullWidth
            value={filter.categoryId}
            options={[{ text: "All" }, ...(categoryNames?.map((x) => ({ text: x.name, value: x.id })) ?? [])]}
            display={"Category"}
            variant="contained"
            color="primary"
            colorLevel="dark"
          />
        )}
      />
      <Box sx={{}}>
        <FilterElement
          title={"Search"}
          render={() => (
            <CustomSearchInput
              value={filter.search}
              onChange={(e) => {
                handleFilter({ search: e, page: 1 });
              }}
              startAdornment={<SearchIcon />}
            />
          )}
        />
      </Box>
    </Card>
  );
}

export default FilterSection;
