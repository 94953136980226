import { Box, Avatar, Typography, IconButton, Badge, SxProps, Card } from "@mui/material";
import ContactTtitleDescView from "./ContactTtitleDescView";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { DeleteIcon, DeliveryMediumType } from "suregifts-component-lib";
import { EditPenBook } from "../../../../icons/Icons";
import { ContactData } from "../../../../services/contact";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { AddItemModel } from "../../../../services/cart";
import { StorageKeys } from "../../../../enums";

type ContactCardProps = {
  data: ContactData;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onNotificaitonClick?: () => void;
};
const rootStyles: SxProps = {
  padding: "1.5625rem",
  borderRadius: "1.5625rem",
  borderWidth: 1,
  position: "relative",
  "& .option-view": {
    display: "none",
  },
  "&:hover .option-view": {
    display: "block !important",
  },
};
export const ContactCard = ({ data, onDeleteClick, onEditClick, onNotificaitonClick }: ContactCardProps) => {
  const navigate = useNavigate();
  return (
    <Card sx={rootStyles}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <RecipientAvata data={data} />
          <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
            {data.firstName} {data.lastName}
          </Typography>
        </Box>
        <IconButton onClick={onNotificaitonClick}>
          <Badge color="error" variant={data.events.length > 0 ? "dot" : "standard"} overlap="circular">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Box>

      <ContactTtitleDescView title={"Contact"} desc={data.email} />
      <ContactTtitleDescView title={"Phone"} desc={data.phone} />
      <ContactTtitleDescView title={"Address"} multiline desc={data.address} />
      <ActionButtonView
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        onSendVoucher={() => {
          const d = {
            deliveryMedium: DeliveryMediumType.Email,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phone,
            address: data.address,
          } as AddItemModel;
          sessionStorage.setItem(StorageKeys.GIFT_PERSONALIZE, JSON.stringify(d));
          navigate("/gift-cards");
        }}
      />
    </Card>
  );
};
const RecipientAvata = ({ data }: { data: ContactData }) => {
  return (
    <Avatar
      sx={{ width: "50px", height: "50px", fontWeight: 700, background: "conic-gradient(from 151.11deg at 50% 50%, #CC9AC7 0deg, #A082F4 13.37deg, #A6E8F7 106.88deg, #FFF5DD 204.37deg, #FFF4D9 305.62deg, #CC9AC7 360deg)" }}
      {...stringAvatar(`${data.firstName} ${data.lastName}`)}
    />
  );
};
const ActionButtonView = ({ onEditClick, onDeleteClick, onSendVoucher }) => {
  return (
    <Box
      className="option-view"
      sx={{
        position: "absolute",
        right: "25px",
        bottom: "25px",
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
        },
      }}
    >
      <IconButton color="success" onClick={onSendVoucher}>
        <SendIcon />
      </IconButton>

      <IconButton onClick={onEditClick}>
        <EditPenBook />
      </IconButton>
      <IconButton color="error" onClick={onDeleteClick}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};
function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
