import { useState } from "react";
import { TabController, TabPanel } from "suregifts-component-lib";

import PersonalInfomationSection from "./PersonalInfomationSection";
import PreviewSection from "./PreviewSection";

function HomeDeliveryTabPanel({}) {
  const [tabIndex, setIndexTab] = useState(0);

  return (
    <div style={{ marginBottom: "80px" }}>
      <TabController
        value={tabIndex}
        fontSize="20px"
        indicatorColor="success.main"
        onChange={(value) => {
          setIndexTab(value);
        }}
        tabs={[
          { label: "Recipient Infromation", value: 0 },
          { label: "Preview Design", value: 1 },
        ]}
      />
      <TabPanel value={tabIndex} index={0}>
        <PersonalInfomationSection />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <PreviewSection />
      </TabPanel>
    </div>
  );
}

export default HomeDeliveryTabPanel;
