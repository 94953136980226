import { Box, useTheme } from "@mui/system";
import React, { useState } from "react";
import { GiftCartSection } from "./GiftCartSection";

import { RecipientsSection } from "./RecipientsSection";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { BackButton } from "../../../components/atoms/BackButton";
import { useGetOrderById } from "../../../services/order";
import { useDocumentTitle } from "suregifts-component-lib";

function OrderInfoScreen() {
  const theme = useTheme();
  const { orderId } = useParams();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const { data } = useGetOrderById(orderId, () => setSelectedIndex(0));
  useDocumentTitle("Order Info");
  return (
    <div style={{ position: "relative" }}>
      <BackButton to={"/orders"} />
      <Box
        sx={{
          borderRadius: "30px",
          "& > div": {
            backgroundColor: "primary.main",
            width: "100%",
            flexGrow: 1,
            padding: "40px 50px",
          },
          display: "flex",
          gap: "2px",
          flexDirection: "column",
          overflow: "hidden",
          [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          },
          position: "relative",
        }}
      >
        <RecipientsSection data={data} changeIndex={(index) => setSelectedIndex(index)} index={selectedIndex} />
        <GiftCartSection data={data} selectedIndex={selectedIndex} />
      </Box>
    </div>
  );
}

export default OrderInfoScreen;
