import { Typography, Stack, InputBase, Box } from "@mui/material";

import { Heading } from "./LinkLinkView";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { FormButton } from "suregifts-component-lib";
import { ContactPhoneEmailView } from "../../pages/static/contact-support/LeftSection";

const SubscribeView = () => {
  return (
    <>
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 700, lineHeight: "1.875rem" }}>Stay connected with us!</Typography>
      <Typography
        sx={{
          color: "text.secondary",
          marginTop: "1rem",
        }}
      >
        We promise to only share innovative gifting ideas.
      </Typography>
      <Stack direction={"row"} sx={{ marginTop: "1rem" }}>
        <InputBase
          sx={{ bgcolor: "primary.main", margin: "0.3125rem 0 ", borderTopLeftRadius: "1.125rem", borderBottomLeftRadius: "1.125rem", paddingLeft: "1.25rem", height: "56px" }}
          fullWidth
          placeholder="Enter email"
          endAdornment={<FormButton label="Submit" sx={{ height: "100%", fontWeight: "bold" }} />}
        />
      </Stack>
    </>
  );
};

export const NewsLetterSection = () => {
  const { initialSetting } = useGlobalContext();

  return (
    <Box>
      <Heading label={"NewsLetter"} />
      <SubscribeView />
      <ContactPhoneEmailView />
    </Box>
  );
};
