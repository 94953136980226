import { useForm } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { FormButton, FormInput, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ClaimPromoModel, useClaimPromo } from "../../services/promo";
const schema = yup
  .object<YupShape<ClaimPromoModel>>({
    email: yup.string().required().email(),
    phone: yup.string().required(),
    companyName: yup.string().required(),
    fullName: yup.string().required(),
  })
  .required();

function FormView({ onSuccess }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ClaimPromoModel>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { mutate: claimPromo, isLoading } = useClaimPromo();
  const submitForm = (model) => {
    claimPromo(model, {
      onSuccess: onSuccess,
    });
  };
  return (
    <form onSubmit={handleSubmit(submitForm)} className="form-border form-root">
      <div>
        <Typography className="form-title">Complete the form to claim your FREE PIZZA</Typography>
      </div>
      <Stack spacing={2} className="form-controls">
        <FormInput required label="Full name" {...register("fullName")} helperText={errors.fullName?.message} />
        <FormInput required label="Company name" {...register("companyName")} helperText={errors.companyName?.message} />

        <FormInput required label="Work Email Address" {...register("email")} helperText={errors.email?.message} />
        <FormInput required label="Phone Number" {...register("phone")} helperText={errors.email?.message} />

        <FormButton color="error" type="submit" label="Grab my free ice cream & pizza" fullWidth loading={isLoading} disabled={!isValid} />
      </Stack>
    </form>
  );
}

export default FormView;
