import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { DialogTitle, FormButton, FormInput, useAuthContext, useNotificationContext } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { ProfileInfo, useSendVerifyEmailCode, useVerifyEmail, VerifyModel } from "../services/auth";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

function EmailVerificationModal() {
  const { message } = useNotificationContext();
  const navigate = useNavigate();
  const { saveToken, user } = useAuthContext();
  const queryClient = useQueryClient();
  const { mutate: verifyEmail, isLoading: isVerifingEmail } = useVerifyEmail();
  const { mutate: sendEmailCode } = useSendVerifyEmailCode();
  const localUser = user as ProfileInfo;
  useEffect(() => {
    if (!localUser.emailConfirmed) {
      sendEmailCode(undefined);
    } else if (!localUser.phoneNumberConfirmed) {
      verifyPhone();
    }
  }, []);

  const submitForm = (model) => {
    verifyEmail(model, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("profile");
        saveToken(data.token, data.refreshToken, data);
        if (!localUser.phoneNumberConfirmed) {
          verifyPhone();
          return;
        }
        message({ title: "Email verified successfully." });
        handleClose();
      },
    });
  };

  const { register, handleSubmit } = useForm<VerifyModel>();
  const handleClose = () => {
    navigate("..");
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title={"Verify Email"} handleClose={handleClose} />
      <DialogContent>
        <FormInput label="Enter Otp" {...register("otpCode")} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Confirm" fullWidth onClick={handleSubmit(submitForm)} loading={isVerifingEmail} />
      </DialogActions>
    </Dialog>
  );

  function verifyPhone() {
    if (localUser.phoneCountryCode && localUser.phoneNumber) {
      navigate("/verify-phone");
    } else {
      navigate("/add-phone");
    }
  }
}

export default EmailVerificationModal;
