import { SectionOne } from "./SectionOne";
import { SectionTwo } from "./SectionTwo";
import { SectionThree } from "./SectionThree";
import { useDocumentTitle } from "suregifts-component-lib";

function HowToUseScreen() {
  useDocumentTitle("How to Use");
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </div>
  );
}

export default HowToUseScreen;
