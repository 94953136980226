import { useCartDeliveryContext } from "../useCartDeliveryContext";

export const LeftTop = () => {
  const { model } = useCartDeliveryContext();
  return (
    <div>
      <img style={{ backgroundColor: "white", objectFit: "cover", width: "100%", height: "100%" }} src={model.bgPicture} />
    </div>
  );
};
