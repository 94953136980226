import { SxProps, Typography } from "@mui/material";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { ContactEmailIcon, ContactPhoneIcon } from "../../../icons/Icons";

export const LeftSection = () => {
  return (
    <div style={{ marginTop: "2.5rem" }}>
      <Typography sx={{ fontSize: "6.25rem", fontWeight: 700, lineHeight: "110%" }}>Need help?</Typography>
      <Typography sx={{ marginTop: "1.5rem" }}>Reach out to us. We’ve got answers to your questions</Typography>
      <ContactPhoneEmailView />
    </div>
  );
};

export const ContactPhoneEmailView = () => {
  const { initialSetting } = useGlobalContext();
  return (
    <div>
      <div style={{ fontSize: "24px", fontWeight: 700, lineHeight: "29px", marginTop: "30px", marginBottom: "13px" }}>Contact us</div>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Typography sx={phoneSx}>
          <ContactEmailIcon /> &nbsp;&nbsp; {initialSetting?.contactEmail}
        </Typography>
        <Typography sx={phoneSx}>
          <ContactPhoneIcon /> &nbsp;&nbsp; {initialSetting?.contactPhone}
        </Typography>
      </div>
    </div>
  );
};
const phoneSx: SxProps = {
  background: "linear-gradient(180deg, rgba(255, 254, 251, 0.01) 0%, rgba(255, 254, 251, 0.13) 100%)",
  padding: "0.75rem 1rem",
  fontSize: "1.125rem",
  lineHeight: "1.375rem",
  display: "flex",
  borderRadius: "50vh",
  alignItems: "center",
};
