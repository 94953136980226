import { DialogContent, Typography } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape, useAuthContext, useNotificationContext } from "suregifts-component-lib";
import { useGlobalContext } from "../contexts/GlobalContext";
import { Stack } from "@mui/system";
import { ForgotPasswordModel, ResetPasswordModel, useForgotPassword, useResetPassword } from "../services/auth";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AuthDialog } from "../components/atoms/AuthDialog";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const schema = yup
  .object<YupShape<ResetPasswordModel>>({
    password: yup.string().required(),
    confirmPassword: yup.string().required(),
  })
  .required();
function ResetPasswordModal() {
  const [searchParams] = useSearchParams();
  const { message } = useNotificationContext();

  const { saveToken, isAuthenticated, user } = useAuthContext();
  const { mutate: resetPassword, isLoading } = useResetPassword();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: searchParams.get("email"),
      resetToken: searchParams.get("token"),
    },
  });

  const submitForm = (model) => {
    resetPassword(model, {
      onSuccess: (data) => {
        navigate("/login");
      },
    });
  };
  return (
    <AuthDialog open maxWidth="xs">
      <DialogTitle
        title="Set New Password"
        handleClose={() => {
          navigate("/");
        }}
      />
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={3}>
            <Typography sx={{ fontWeight: 500, lineHeight: "25px" }}>Please enter your new password</Typography>
            <FormInput required label="Password" {...register("password")} helperText={errors.password?.message} type="password" />
            <FormInput required label="Confirm Password" {...register("confirmPassword")} type="password" helperText={errors.confirmPassword?.message} />
          </Stack>
          <FormButton loading={isLoading} type="submit" label="Change Password" fullWidth disabled={!isValid} />
        </form>
      </DialogContent>
    </AuthDialog>
  );
}
export default ResetPasswordModal;
