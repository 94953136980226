import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
type GreetingCardImageViewProps = {
  url: string;
  onDeleteClick?: () => void;
  deletable?: boolean;
  onClick?: () => void;
  selected?: boolean;
};

export const GreetingCardImageView = ({ url, onClick, onDeleteClick, deletable = true, selected = false }: GreetingCardImageViewProps) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        position: "relative",
        "& .MuiIconButton-root": {
          opacity: 0,
        },
        "&:hover .MuiIconButton-root": {
          opacity: 1,
        },
        height: "95px",
        padding: "3px",
        borderRadius: "5px",
        border: 2,
        borderColor: "transparent",
        ...(selected && {
          borderColor: "secondary.main",
        }),
      }}
    >
      <Box
        component={"img"}
        src={url}
        sx={{
          width: "68px",
          maxWidth: "68px",
          height: "85px",
          borderRadius: "5px",
          overflow: "hidden",
          objectFit: "cover",
        }}
      />
      {deletable && (
        <IconButton onClick={onDeleteClick} sx={{ position: "absolute", top: "0", right: 0, border: 2, padding: "0px" }} size="small">
          <CloseIcon sx={{ height: "16px", width: "16px" }} />
        </IconButton>
      )}
    </Box>
  );
};
