import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const HowItWorkSection = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "center", marginTop: "8.875rem" }}>
      <Typography sx={{ fontSize: "2.8125rem", fontWeight: 700, lineHeight: "110%" }}>How it works</Typography>
      <Typography sx={{ fontSize: "1.125rem", color: "text.disabled", marginTop: "1rem" }}>
        Buy and send gift cards from over 200 merchants across <br />
        Nigeria in 3 easy steps.
      </Typography>
      <Box component={"img"} src="/assets/images/bg/2.png" sx={{ display: "block", marginInline: "auto", marginTop: "5rem", width: "100%", maxWidth: "md" }} />
      <FormButton label="Buy a Gift Card" onClick={() => navigate("/gift-cards")} />
    </div>
  );
};
