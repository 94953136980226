import { Box } from "@mui/material";
import { MarginTop40Box, PageHeaderTitle, SearchButton, SearchContainer, SearchInput } from "suregifts-component-lib";
import { useWalletContext } from "./WalletContext";
import { useExportPayments } from "../../../services/transaction";
const statuses = [{ text: "All" }, { text: "Active", value: true }, { text: "Inactive", value: false }];
function FilterSection({ handleFilter, filter }) {
  const { dispatch, state } = useWalletContext();
  const { mutate: exportData } = useExportPayments(filter);
  return (
    <SearchContainer>
      <PageHeaderTitle title={"Recent Transactions"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <SearchInput
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e });
          }}
        />

        <SearchButton sx={{ py: "0.125rem" }} label="Export" onClick={() => exportData()} />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
