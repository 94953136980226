import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import { DialogTitle, FormButton, GiftCardInfoData, GiftPriceView, LoadingBox } from "suregifts-component-lib";
import { useState } from "react";
import { useGetGiftCardById } from "../../services/gift";
import { Box } from "@mui/system";
import { StorageKeys } from "../../enums";
import { AddItemModel } from "../../services/cart";
import { useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const GiftInfoDialog = () => {
  const theme = useTheme();
  const { giftId } = useParams();
  const { data: gift } = useGetGiftCardById(giftId);
  const navigate = useNavigate();
  return (
    <Dialog
      onClose={() => {
        navigate("/gift-cards");
      }}
      maxWidth="md"
      open
      PaperProps={{
        sx: { "&.MuiPaper-root": { borderRadius: "25px" } },
      }}
    >
      <DialogTitle
        title={""}
        handleClose={() => {
          navigate("/gift-cards");
        }}
      />
      <DialogContent
        sx={{
          display: "grid",
          gap: "50px",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr",
          },
        }}
      >
        {!!gift ? (
          <>
            <GiftInfoSection gift={gift} />
            <PriceSection gift={gift} />
          </>
        ) : (
          <LoadingBox loading />
        )}
      </DialogContent>
    </Dialog>
  );
};
type GiftInfoSectionProps = {
  gift: GiftCardInfoData;
};
const GiftInfoSection = ({ gift }: GiftInfoSectionProps) => {
  const [index, setIndex] = useState(0);
  const handleChange = (newIndex: number) => {
    if (index == newIndex) {
      setIndex(-1);
    } else {
      setIndex(newIndex);
    }
  };
  return (
    <div style={{ display: "grid", gap: "20px" }}>
      <div style={{}}>
        <Box
          component={"img"}
          sx={{
            width: "100%",
            maxWidth: "310px",
            display: "block",
            marginInline: "auto",
            borderRadius: "10px",
            maxHeight: "185px",
            objectFit: "cover",
          }}
          src={gift.imageUrl}
        />
      </div>
      <Box
        sx={{
          maxHeight: "400px",
          overflow: "scroll",
          lineHeight: "25px !importan",
          fontSize: "14px",
          ".MuiAccordion-root": {
            background: "none",
            ul: {
              margin: 0,
              paddingLeft: "16px",
              color: "text.secondary",
              li: {
                paddingBottom: "10px",
              },
            },
          },
        }}
      >
        <Accordion expanded={index == 0} onChange={() => handleChange(0)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: "20px", fontWeight: "700", lineHeight: "24px" }}>Description</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={"p"} sx={{ fontSize: "14px", lineHeight: "25px", color: "text.secondary" }}>
              {gift.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={index == 1} onChange={() => handleChange(1)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: "20px", fontWeight: "700", lineHeight: "24px" }}>Redemption Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {gift.redmeptionDetails.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={index == 2} onChange={() => handleChange(2)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: "20px", fontWeight: "700", lineHeight: "24px" }}>Redemption Location</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {gift.locations.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

type PriceSectionProps = {
  gift: GiftCardInfoData;
};
const PriceSection = ({ gift }: PriceSectionProps) => {
  const [value, setValue] = useState(gift.minAmount);
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", gap: "20px", justifyContent: "flex-start", flexDirection: "column" }}>
      <GiftPriceView
        gift={gift}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      />
      <div style={{ textAlign: "center" }}>
        <FormButton
          label="Personalize Gift Card"
          fullWidth
          onClick={() => {
            let sessionData = sessionStorage.getItem(StorageKeys.GIFT_PERSONALIZE) || "{}";
            const model = JSON.parse(sessionData) as AddItemModel;
            model.giftId = gift.giftId;
            model.amount = value;
            sessionStorage.setItem(StorageKeys.GIFT_PERSONALIZE, JSON.stringify(model));
            navigate("/cart/personalize");
          }}
        />
        <Typography sx={{ fontSize: "14px", color: "text.secondary", marginTop: "10px" }}>Step 1 of 3</Typography>
      </div>
    </div>
  );
};
