import { Box, Typography, IconButton } from "@mui/material";
import { DeleteIcon, dateOnly } from "suregifts-component-lib";
import { EditPenBook } from "../../../../icons/Icons";
import { EventModel, EventData } from "../../../../services/contact";
type EventListItemProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
  data: EventModel | EventData;
};

export const EventListItem = ({ data: item, onEditClick, onDeleteClick }: EventListItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "25px",
        borderRadius: "30px",
        "& .action-div": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: "primary.dark",
          "& .action-div": {
            display: "block",
          },
        },
      }}
    >
      <div>
        <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "22px" }}>{item.title}</Typography>
        <Typography sx={{ fontWeight: 500, lineHeight: "20px", color: "text.secondary", marginTop: "10px", fontSize: "14px" }}>{dateOnly(item.startDate)}</Typography>
      </div>
      <div className="action-div">
        <IconButton onClick={onEditClick}>
          <EditPenBook sx={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton color="error" onClick={onDeleteClick}>
          <DeleteIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </div>
    </Box>
  );
};
