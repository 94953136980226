import { TableHead, TableRow, TableCell, TableBody, Box, Typography, IconButton, SxProps } from "@mui/material";
import { PageHeaderTitle, MainTable, SelectPopover } from "suregifts-component-lib";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AddItemModel, CartItemDto, useCloneItem, useRemoveItem } from "../../../services/cart";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { StorageKeys } from "../../../enums";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
const tableRootStyle: SxProps = {
  overflow: "hidden",
  borderRadius: "1.25rem",
  "& td": {
    "&.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:not(:first-type-of)": {
      fontWeight: 500,
      fontSize: "14px !important",
    },
    padding: "20px !important",
    backgroundColor: "primary.main",
    "&:first-of-type": {
      borderTopLeftRadius: "1.25rem",
      borderBottomLeftRadius: "1.25rem",
    },
    "&:last-of-type": {
      borderTopRightRadius: "1.25rem",
      borderBottomRightRadius: "1.25rem",
    },
  },
};
export const CartItemsListSection = () => {
  const { cart, refetchCart } = useGlobalContext();
  const { mutate: cloneItem, isLoading: isCloning } = useCloneItem();
  const { mutate: removeItem, isLoading: isRemoving } = useRemoveItem();
  const navigate = useNavigate();
  const handleEdit = (item: CartItemDto) => {
    const t: AddItemModel = {
      amount: numeral(item.value).value() || 0,
      firstName: item.firstName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      deliveryMedium: item.deliveryMediumId,
      lastName: item.lastName,
      address: item.address,
      city: item.city,
      stateId: item.stateId,
      picture: item.picture,
      message: item.message,
      bgPicture: item.bgPicture,
      giftId: item.giftId,
      isValid: true,
    };

    sessionStorage.setItem(StorageKeys.GIFT_PERSONALIZE, JSON.stringify(t));

    navigate(`/cart/personalize?id=${item.id}`);
  };
  return (
    <div>
      <PageHeaderTitle title="Order Summary" />
      <MainTable sx={{ marginTop: "30px", borderCollapse: "separate", borderSpacing: "0 15px" }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                "&.MuiTableCell-root.MuiTableCell-head": {
                  paddingLeft: "20px",
                },
              }}
            >
              Item
            </TableCell>
            <TableCell>Recipient Name</TableCell>
            <TableCell>Medium</TableCell>
            <TableCell>Gift Value</TableCell>
            <TableCell>Bonus</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart?.items.map((item) => (
            <TableRow key={item.id} sx={tableRootStyle}>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "100px", height: "60px", borderRadius: "10px", objectFit: "cover" }} component="img" src={item.imageUrl} />
                  <div>
                    <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>{item.title}</Typography>
                    <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>Spa and Health Club</Typography>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                {item.firstName} {item.lastName}
              </TableCell>
              <TableCell>{item.deliveryMedium}</TableCell>
              <TableCell>{item.value}</TableCell>
              <TableCell>{item.bonus}</TableCell>
              <TableCell>
                <SelectPopover
                  anchorOrigin={{
                    horizontal: "left",
                    vertical: "center",
                  }}
                  onChange={(index, value) => {
                    if (index === 0) {
                      cloneItem(item.id, {
                        onSuccess: () => {
                          refetchCart();
                        },
                      });
                    } else if (index === 1) {
                      handleEdit(item);
                    } else if (index === 2) {
                      removeItem(item.id, {
                        onSuccess: () => {
                          refetchCart();
                        },
                      });
                    }
                  }}
                  options={[{ text: "Duplicate" }, { text: "Edit" }, { text: "Delete", color: "error" }]}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
};
