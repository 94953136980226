import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { FormButton, FormInput, FormSelect, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MerchantModel, useMerchantRegister } from "../../../services/merchant";
import { useGetIndustries } from "../../../services/general";
const schema = yup
  .object<YupShape<MerchantModel>>({
    email: yup.string().required().email(),
    contactName: yup.string().required(),
    phone: yup.string().required(),
    address: yup.string().required(),
    industryId: yup.string().required(),
    name: yup.string().required(),
    rcNumber: yup.string().required(),
  })
  .required();
function FormSection() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<MerchantModel>({
    resolver: yupResolver(schema),
    defaultValues: { industryId: "" },
  });
  const { data: industries } = useGetIndustries();
  const { mutate: submit, isLoading } = useMerchantRegister();
  const submitForm = (model) => {
    submit(model, {
      onSuccess: () => {
        toast.success("Registration submitted successfully.");
        navigate("/");
      },
    });
  };
  return (
    <Box
      style={{ background: "linear-gradient(180deg, rgba(255, 254, 251, 0.01) 0%, rgba(255, 254, 251, 0.13) 100%)", backdropFilter: "blur(1.25rem)", borderRadius: "1.25rem", border: "0.125rem solid #353439" }}
      component="form"
      onSubmit={handleSubmit(submitForm)}
    >
      <div style={{ padding: "2.375rem 2.125rem 1.25rem", display: "flex", gap: "1rem" }}>
        <Box component={"img"} src="/assets/images/customer-agent.png" sx={{ width: "4.8125rem", height: "4.8125rem", borderRadius: "50vh" }} />
        <div>
          <Typography sx={{ fontSize: "1.5rem", lineHeight: "1.875rem" }}>Hello, I'm Jennifer</Typography>
          <Typography sx={{ lineHeight: "1.25rem", mt: "0.5rem" }}>Please fill the form below and I'll get back to you in a jiffy 😀 with the next steps</Typography>
        </div>
      </div>
      <hr style={{ borderColor: "#2F2E32" }} />
      <div style={{ padding: "2.125rem" }}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="Brand / Business Name" {...register("name")} helperText={errors.name?.message} />
            <FormInput required label="CAC/RC Number" {...register("rcNumber")} helperText={errors.rcNumber?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="Email Address" {...register("email")} helperText={errors.email?.message} />
            <FormInput required label="Contact Person's Name*" {...register("contactName")} helperText={errors.contactName?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput required label="Address" {...register("address")} helperText={errors.address?.message} />
            <FormInput required label="Phone Number" {...register("phone")} helperText={errors.phone?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Controller control={control} name="industryId" render={({ field }) => <FormSelect required helperText={errors.industryId?.message} label="Category" {...field} options={industries} />} />
            <div style={{ width: "100%" }}></div>
          </Stack>
          <FormButton type="submit" label="Submit" fullWidth loading={isLoading} disabled={!isValid} />
        </Stack>
      </div>
    </Box>
  );
}

export default FormSection;
