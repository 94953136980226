import { Stack } from "@mui/material";
import { PageHeaderTitle, WalletType, getPaymentPath } from "suregifts-component-lib";
import { useCartContext } from "./CartContext";
import { useEffect } from "react";
import { useGetVoucherPaymentMethods } from "../../../services/transaction";
import { FundButton } from "../../profile/wallet/BalanceCard";

export const PaymentMethodSection = () => {
  const { data: methods } = useGetVoucherPaymentMethods();
  const { model, updateModel, calcAmount } = useCartContext();
  useEffect(() => {
    if (model.paymentMethod) {
      updateModel({ returnPath: `payment_callback/${getPaymentPath(model.paymentMethod)}` });
    }
  }, [model.paymentMethod]);
  useEffect(() => {
    if (calcAmount.outstanding <= 0) {
      updateModel({ paymentMethod: WalletType.None });
    }
  }, [calcAmount.outstanding]);
  if (calcAmount.outstanding <= 0) {
    return <></>;
  }
  return (
    <div>
      <PageHeaderTitle title="Payment Method" />
      <Stack spacing={"0.625rem"} sx={{ marginTop: "20px" }}>
        {methods?.map((item) => (
          <FundButton key={item.id} method={item} selected={model.paymentMethod === item.id} onClick={() => updateModel({ paymentMethod: item.id })} />
        ))}
      </Stack>
    </div>
  );
};
