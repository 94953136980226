import { Container, useTheme } from "@mui/material";
import { PressSection } from "./PressSection";
import BlogSection from "./BlogSection";
import { useDocumentTitle } from "suregifts-component-lib";

function BlogScreen() {
  useDocumentTitle("Blog");
  return (
    <Container>
      <PressSection />
      <BlogSection />
    </Container>
  );
}

export default BlogScreen;
