import { Box, useTheme } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

export const NavigationSection = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const links = [
    { text: "Home", to: "/" },
    { text: "Airtime", to: "/airtime" },
    { text: "Pay bills", to: "/bills" },
    { text: "Merchants", to: "/merchant" },
  ];
  return (
    <Box className="nav" component={"nav"}>
      <ul
        style={{ display: "flex", gap: "0.125rem", listStyle: "none", margin: 0, padding: 0, fontSize: "0.875rem", fontWeight: 500, lineHeight: "1.6875rem" }}
      >
        {links.map((link, index) => (
          <li key={index}>
            <Box
              component={Link}
              sx={{
                textDecoration: "none",
                color: theme.palette.text.primary,
                padding: "1rem",
                borderRadius: "1rem",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
                ...(link.to === pathname && {
                  backgroundColor: "primary.light",
                }),
              }}
              to={link.to || ""}
            >
              {link.text}
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
};
