import { Box, SxProps, Typography } from "@mui/material";
import { EditPencilIcon, FileUploader } from "suregifts-component-lib";
import { useCartPersonalizeContext } from "./CartPersonalizeContext";

import { useState } from "react";

const rootStyles: SxProps = {
  padding: "20px 25px",
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  gap: "1rem",
  "& > div": {
    overflow: "hidden",
    borderRadius: "20px",
    padding: "10px",
    position: "relative",
  },
};
export const UserPreferenceSection = () => {
  const { model, updateModel } = useCartPersonalizeContext();
  const [editing, setEditing] = useState(false);
  return (
    <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "670px 200px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "338px 338px",
          borderRadius: "20px",
          overflow: "hidden",
          maxWidth: "676px",
          "& > div.bg-img": {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            maxHeight: "462px",
            height: "462px",
            backgroundImage: `url(${model.bgPicture})`,
          },
          "&  div.editor": {
            height: "100%",
            borderRadius: "14px",
            border: "2px dashed #AAAABE",
            overflow: "hidden",
            backgroundColor: "white",
            position: "relative",
            "& textarea": {
              resize: "none",
              border: "none",
              width: "100%",
              height: "100%",
              fontSize: "16px",
            },
          },
        }}
      >
        <div className="bg-img"></div>
        <Box sx={rootStyles} className="bg-img">
          <div className="editor">
            <textarea
              value={model.message}
              onChange={(e) => {
                if (e.target.value.length >= 100) {
                  return;
                }

                updateModel({ message: e.target.value });
              }}
            />
            {!editing && (
              <div style={{ position: "absolute", left: 0, top: 0, height: "100%", width: "100%", background: "#ffffff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => setEditing(true)}>
                <EditPencilIcon sx={{ color: "#AAAABE" }} />
                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#AAAABE" }}>Click to edit message</Typography>
              </div>
            )}
          </div>
          <FileUploader
            onChange={(path) => updateModel({ picture: path as string })}
            renderControl={(control) => (
              <div style={{ backgroundColor: "black" }} onClick={control.onClick}>
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {model.picture ? (
                      <img style={{ objectFit: "scale-down", width: "100%" }} src={model.picture} alt="" />
                    ) : (
                      <>
                        <img src="/assets/images/photo-bg.png" alt="" />
                        <span>Add Photo</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </Box>
      </Box>
    </div>
  );
};
