import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ShowActionType, TransactionStatusType, WalletType, getPaymentType, useAuthContext, useNotificationContext } from "suregifts-component-lib";
import { useVerify } from "../services/transaction";
import { useRetrievePaymentData } from "./useRetrievePaymentData";
import { useQueryClient } from "react-query";

function usePaymentCallback() {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { message } = useNotificationContext();
  const { mutate: verifyPayment, isLoading, data } = useVerify();
  const { pathname } = useLocation();
  const { invoiceNumber, providerRef, paymentMethod } = useRetrievePaymentData();
  useEffect(() => {
    if (pathname.indexOf("/funding_callback") === -1) return;

    if ((!invoiceNumber && !providerRef) || !paymentMethod) {
      return;
    }
    const handleClose = () => {
      queryClient.invalidateQueries("transactions");
      queryClient.invalidateQueries("wallet-info");
    };
    verifyPayment(
      {
        invoiceNumber,
        paymentMethod,
        providerRef,
      },
      {
        onSuccess: (data) => {
          let modalOption: ShowActionType | undefined = undefined;
          if (data.statusId === TransactionStatusType.Successful) {
            modalOption = {
              desc: "You have successfully topped up your balance",
              title: "Payment Successful",
              buttonText: "Close",
              onClose: handleClose,
            };
          } else if (data.statusId === TransactionStatusType.Failed) {
            modalOption = {
              desc: "There was an error precessing your transaction, please contact your bank or try again",
              title: "Payment Failed",
              buttonText: "Close",
              onClose: handleClose,
            };
          } else {
            modalOption = {
              title: "Processing",
              desc: "Your payment is currently been processed.",
              buttonText: "Close",
            };
          }
          message(modalOption);
        },
      }
    );
    if (isAuthenticated) navigate("/wallet");
  }, []);
}

export default usePaymentCallback;
