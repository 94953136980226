import { FormControlLabel, Checkbox, Stack, Box } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { CustomInput, FormButton } from "suregifts-component-lib";
import { useCartContext } from "./CartContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { useApplyCoupon, useRemoveCoupon } from "../../../services/cart";

export const CouponSection = () => {
  const { cart, refetchCart } = useGlobalContext();
  const { model, updateModel, wallet } = useCartContext();
  const { mutate: applyCoupon, isLoading } = useApplyCoupon();
  const { mutate: removeCoupon } = useRemoveCoupon();
  const [couponCode, setCouponCode] = useState("");
  const handleApplyCoupon = () => {
    applyCoupon(
      { couponCode },
      {
        onSuccess: () => {
          refetchCart();
          toast.success("Coupon applied successfully.");
          setCouponCode("");
        },
      }
    );
  };
  const handleRemoveCoupon = () => {
    removeCoupon(
      {},
      {
        onSuccess: () => {
          refetchCart();
          toast.success("Coupon removed successfully.");
        },
      }
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <span style={{ display: "block", fontWeight: 600 }}>Pay with Wallet</span>
        <FormControlLabel value={model.useWallet} onChange={(e) => updateModel({ useWallet: (e as React.ChangeEvent<HTMLInputElement>).target.checked })} control={<Checkbox color="success" />} label={`Balance: ${wallet?.balanceString}`} />
      </div>
      {!cart?.hasCoupon ? (
        <Stack spacing={2} direction="row" alignItems="center">
          <CustomInput value={couponCode} placeholder="Enter Coupon" size="small" onChange={(e) => setCouponCode(e.target.value)} />
          <FormButton label="Apply Coupon" disabled={!couponCode} color="primary" onClick={handleApplyCoupon} />
        </Stack>
      ) : (
        <FormButton label="Remove Coupon" color="error" onClick={handleRemoveCoupon} />
      )}
    </Box>
  );
};
