import { Typography, Box, Container } from "@mui/material";
import { InstructionBox } from "./InstructionBox";

export const SectionTwo = () => {
  return (
    <Container sx={{ marginTop: "9rem", textAlign: "center" }}>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, lineHeight: "3.75rem" }}>
        How to Redeem Gift Cards
        <Typography component={"span"} sx={{ color: "success.main", font: "inherit" }}>
          {" "}
          Online
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: "3.75rem", justifyContent: "center", marginTop: "5rem", flexWrap: "wrap", whiteSpace: "normal" }}>
        <InstructionBox
          image={"/assets/images/how-to-use/1.png"}
          title={"Select an Item"}
          info={
            <>
              Visit online stores and add items <br />
              to cart
            </>
          }
        />
        <InstructionBox
          image={"/assets/images/how-to-use/2.png"}
          title={"Insert Voucher Code"}
          info={
            <>
              Enter your 8 or 12-digit <br />
              Suregifts voucher code and 4-digit PIN, <br /> making 12 or 16 digits total, <br />
              in the gift card box on the cart page.
            </>
          }
        />
        <InstructionBox
          image={"/assets/images/how-to-use/3.png"}
          title={"Update Cart"}
          info={
            <>
              Update the cart by applying the voucher <br /> code and proceed to checkout
            </>
          }
        />
      </Box>
    </Container>
  );
};
