import { Box, Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormButton, FormInput, PageHeaderTitle, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ChangePinModel, useChangePin, useResetPin } from "../../../services/auth";
import { useState } from "react";

const schema = yup
  .object<YupShape<ChangePinModel>>({
    currentPin: yup.string().required(),
    newPin: yup.string().required(),
    confirmPin: yup.string().required(),
  })
  .required();
export const ChangePinSection = () => {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<ChangePinModel>({
    defaultValues: {
      newPin: "",
      confirmPin: "",
      currentPin: "",
    },
    resolver: yupResolver(schema),
  });

  const { mutate: changePin, isLoading } = useChangePin();
  const submitForm = (model) => {
    changePin(model, {
      onSuccess: () => {
        toast.success("Pin changed successfully.");
        reset();
      },
    });
  };
  const { mutate: resetPin, isLoading: isResetingPin } = useResetPin();

  return (
    <form onSubmit={handleSubmit(submitForm)} style={{ position: "relative" }}>
      <PageHeaderTitle title="Change Pin" />
      <Box style={{ fontSize: "14px", fontStyle: "italic" }}>Pin must be 4 digits</Box>
      <Button
        disabled={isResetingPin}
        onClick={() => {
          resetPin(undefined, {
            onSuccess: () => {
              toast.success("Pin reset was successfully. A new PIN was sent to your email.");
            },
          });
        }}
        variant="text"
        color="success"
        sx={{ position: "absolute", padding: 0, top: "50px", right: 0, fontSize: "14px", zIndex: 3 }}
      >
        Reset PIN
      </Button>
      <Stack spacing={3} sx={{ paddingTop: "30px", position: "relative" }}>
        <FormInput required label="Current Pin" type="password" isNumber charLength={4} {...register("currentPin")} value={watch("currentPin")} helperText={errors.currentPin?.message} />

        <Stack spacing={2} direction="row">
          <FormInput required label="New Pin" isNumber type="password" {...register("newPin")} value={watch("newPin")} helperText={errors.newPin?.message} charLength={4} />
          <FormInput required label="Confirm Pin" isNumber type="password" {...register("confirmPin")} value={watch("confirmPin")} helperText={errors.confirmPin?.message} charLength={4} />
        </Stack>
        <FormButton label="Submit" loading={isLoading} disabled={!isValid} type="submit" sx={{ alignSelf: "flex-start" }} />
      </Stack>
    </form>
  );
};
