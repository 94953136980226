import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "suregifts-component-lib";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuthContext();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
