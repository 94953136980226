import { Box, Button, Tooltip, Typography } from "@mui/material";
import { ProfileTopButton } from "./ProfileTopButton";
import { StatusLabel } from "./StatusLabel";
import { Stack } from "@mui/system";
import { ErrorTickIcon, SuccessTickIcon } from "suregifts-component-lib";
import { useAccountContext } from "./AccountContext";
import { useNavigate } from "react-router-dom";
import UserStatusView from "../../../components/organisms/UserStatusView";
import { useMemo } from "react";
import { toast } from "react-toastify";
const TitleDetailsView = ({ title, details }) => {
  return (
    <>
      <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "17px", color: "text.secondary" }}>{title}</Typography>
      <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "22px" }}>{details}</Typography>
    </>
  );
};
type PhoneEmailStatusviewProps = {
  title?;
  details?;
  onClick?;
  status?: boolean;
};

const PhoneEmailStatusview = ({ title, details, onClick, status }: PhoneEmailStatusviewProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Stack spacing={1}>
        <TitleDetailsView title={title} details={details} />
        {!status && (
          <Button onClick={onClick} sx={{ padding: 0, justifyContent: "flex-start", lineHeight: "17px", width: "auto", alignSelf: "flex-start" }} color="success" variant="text">
            Verify now
          </Button>
        )}
      </Stack>
      {status ? <SuccessTickIcon sx={{ color: "success.main" }} /> : <ErrorTickIcon sx={{ color: "error.main" }} />}
    </Box>
  );
};
function ProfileStatusSection() {
  const { profile } = useAccountContext();
  const navigate = useNavigate();

  const phoneNumber = useMemo(() => {
    if (!profile?.profileInfo.phoneNumber) return "NA";
    return profile.profileInfo.phoneDialCode + " " + profile.profileInfo.phoneNumber;
  }, [profile]);
  const copyCode = () => {};
  return (
    <Box sx={{}}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <UserStatusView actionComponent={<StatusLabel />} />
        <ProfileTopButton
          label={"Edit"}
          onClick={() => {
            navigate("/account-settings");
          }}
        />
      </div>
      <Stack sx={{ marginTop: "45px" }} spacing={"30px"}>
        <PhoneEmailStatusview
          title={"Email"}
          details={profile?.profileInfo.email}
          onClick={() => {
            navigate("verify-email");
          }}
          status={profile?.profileInfo.emailConfirmed}
        />
        <PhoneEmailStatusview
          title={"Phone"}
          details={phoneNumber}
          onClick={() => {
            navigate("verify-phone");
          }}
          status={profile?.profileInfo.phoneNumberConfirmed}
        />
        <Stack spacing={1}>
          <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "17px", color: "text.secondary" }}>Referral Code</Typography>
          <Tooltip title="Copy to clipboard" arrow placement="top-start">
            <Typography
              onClick={() => {
                if (profile?.profileInfo.referralCode) {
                  navigator.clipboard.writeText(profile!.profileInfo.referralCode);
                  toast.success(`Copied: ${profile.profileInfo.referralCode}`);
                }
              }}
              sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "22px", cursor: "pointer" }}
            >
              {profile?.profileInfo.referralCode}
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ProfileStatusSection;
