import { useTheme } from "@mui/system";
import { BlogDetailsSection } from "./BlogDetailsSection";
import { MoreBlockSection } from "./MoreBlockSection";
import { useDocumentTitle } from "suregifts-component-lib";

function BlogInfoScreen() {
  useDocumentTitle("Blog");
  return (
    <>
      <BlogDetailsSection />
      <MoreBlockSection />
    </>
  );
}

export default BlogInfoScreen;
