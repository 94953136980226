import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useIsLinkActive = (to) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() => {
    return to === pathname || pathname.indexOf(`${to}/`) !== -1;
  }, [pathname]);
  return isActive;
};
