import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DeliveryMediumType, FormButton, FormInput, TabPanel, YupShape } from "suregifts-component-lib";
import { AddItemModel } from "../../../../services/cart";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Typography from "@mui/material/Typography";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
const schema = yup
  .object<YupShape<AddItemModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),

    phoneNumber: yup.string().required(),
  })
  .required();

function EmailTabPanel({}) {
  const { model, updateModel } = useCartDeliveryContext();
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm<AddItemModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      phoneNumber: model.phoneNumber,
    },
  });

  useEffect(() => {
    updateModel({ ...watch(), isValid: isValid });
  }, [watch("email"), watch("firstName"), watch("lastName"), watch("phoneNumber"), isValid]);

  return (
    <div style={{ marginBottom: "80px" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Recipient Infromation</Typography>
      <Stack sx={{ marginTop: "30px" }} spacing="30px">
        <Stack spacing={2} direction="row">
          <FormInput required label="First name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput required label="Last name" {...register("lastName")} helperText={errors.lastName?.message} />
        </Stack>
        <Stack spacing={2} direction="row">
          <FormInput required label="Email Address" {...register("email")} helperText={errors.email?.message} />
          <FormInput label="Phone Number" {...register("phoneNumber")} />
        </Stack>
      </Stack>
    </div>
  );
}

export default EmailTabPanel;
