import { Box, Typography, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const SectionOne = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        marginTop: "3.125rem",
        display: "grid",
        alignItems: "center",
        gap: "2rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },
      }}
    >
      <div>
        <Typography sx={{ fontSize: "3.5rem", fontWeight: 700, lineHeight: "130%" }}>
          A Gift Card: <br />
          The perfect gift for any occasion
        </Typography>
        <Typography sx={{ fontSize: "1.125rem", fontWeight: 500, lineHeight: "2rem", color: "text.secondary" }}>
          Make any occasion special with a gift card that <br /> offers endless possibilities. It is the smart choice.
        </Typography>
        <Stack direction={"row"} gap="0.625rem" sx={{ marginTop: "2.5rem" }}>
          <FormButton label="Buy a Gift Card" onClick={() => navigate("/gift-cards")} />
          <FormButton label="Contact Support" color="primary" onClick={() => navigate("/contact-support")} />
        </Stack>
      </div>
      <Box component={"img"} src="/assets/images/bg/1.png" sx={{ width: "100%" }} />
    </Box>
  );
};
