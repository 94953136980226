import { Box, Button, DialogActions, DialogContent, FormControlLabel, Checkbox, Typography, FormControlLabelProps, CheckboxProps } from "@mui/material";
import { DialogTitle, FormButton, FormInput, FormSelect, YupShape, useAuthContext } from "suregifts-component-lib";
import { Stack } from "@mui/system";
import { RegisterModel, useGetPhoneCountries, useRegister } from "../services/auth";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AuthDialog } from "../components/atoms/AuthDialog";
import { Link, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useEffect, useMemo } from "react";
const schema = yup
  .object<YupShape<RegisterModel>>({
    email: yup.string().required().email(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required(),
    phone: yup.string().required(),
    phoneCountryCode: yup.string().required(),
  })
  .required();
function RegisterModal() {
  const { user, saveToken, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };
  const { mutate: signup, isLoading } = useRegister();
  const { data: countryNames } = useGetPhoneCountries();

  const countries = useMemo(() => {
    return countryNames?.map((x) => ({
      id: x.isoCode,
      name: x.name,
    }));
  }, [countryNames]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<RegisterModel>({
    resolver: yupResolver(schema),
  });
  const submitForm = (model) => {
    if (!watch("acceptTerms")) {
      toast.info("You must accept terms and conditions");
      return;
    }
    signup(model, {
      onSuccess: (data) => {
        saveToken(data.token, data.refreshToken, { ...user, ...data });
      },
    });
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/verify-email");
    }
  }, [user]);
  return (
    <AuthDialog open>
      <DialogTitle title="Create a new account" handleClose={handleClose} />
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={2}>
            <Stack spacing={2} direction="row">
              <FormInput required label="First Name" {...register("firstName")} helperText={errors.lastName?.message} />
              <FormInput required label="Last Name" {...register("lastName")} helperText={errors.firstName?.message} />
            </Stack>
            <FormInput required label="Email" {...register("email")} helperText={errors.email?.message} />

            <Stack spacing={2} direction={"row"}>
              <Controller
                control={control}
                name="phoneCountryCode"
                render={({ field }) => <FormSelect required helperText={errors.phoneCountryCode?.message} {...field} label={"Country"} options={countries} />}
              />
              <FormInput isNumber value={watch("phone")} required label="Phone" {...register("phone")} helperText={errors.phone?.message} />
            </Stack>
            <Stack spacing={2} direction="row">
              <FormInput required label="Password" {...register("password")} type="password" helperText={errors.password?.message} />
              <FormInput required label="Confirm Password" {...register("confirmPassword")} type="password" helperText={errors.confirmPassword?.message} />
            </Stack>
            <FormInput label="Referral Code (Optional)" {...register("referrerCode")} />
          </Stack>
          <div style={{ marginTop: "20px" }}>
            <Controller
              control={control}
              name="acceptTerms"
              render={({ field: { onChange, value } }) => (
                <LocalCheckbox
                  value={value}
                  onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
                  label={
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      I accept the{" "}
                      <LocalLink color="success.main" to="/terms-and-condition" onClick={handleClose}>
                        terms & condition
                      </LocalLink>{" "}
                      and{" "}
                      <LocalLink to="/faq" color="success.main" onClick={handleClose}>
                        privacy policy
                      </LocalLink>
                    </Typography>
                  }
                />
              )}
            />
            <LocalCheckbox
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
              label={"I want to receive SureGifts's Newsletter"}
            />
          </div>
          <FormButton loading={isLoading} type="submit" label="Sign up" fullWidth disabled={!isValid} />
        </form>
      </DialogContent>
      <DialogActions>
        <Typography>Already have an account? </Typography>
        <Button variant="text" color="success" sx={{ padding: 0 }} onClick={() => navigate("/login")}>
          Sign In
        </Button>
      </DialogActions>
    </AuthDialog>
  );
}
type LocalCheckboxProps = {
  color?: CheckboxProps["color"];
} & Omit<FormControlLabelProps, "control">;
const LocalCheckbox = ({ color = "success", ...others }: LocalCheckboxProps) => {
  return (
    <FormControlLabel value="female" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} color={color} />} {...others} />
  );
};
type LocalLinkProps = {
  to?: string;
  children: React.ReactNode;
  color?: string;
  fontSize?: string;
  onClick?: (e) => void;
};
const LocalLink = ({ to = "", children, color = "inherit", fontSize, onClick = () => {} }: LocalLinkProps) => {
  return (
    <Box component={Link} to={to} sx={{ color, textDecoration: "none", fontSize }} onClick={onClick}>
      {children}
    </Box>
  );
};
export default RegisterModal;
