import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { AirtimeFormSection, TransactionType, WalletType, useDocumentTitle, useUtilityContent } from "suregifts-component-lib";

import { useParams } from "react-router-dom";

import { PaymentSection } from "../../PaymentSection";

function AirtimeCheckoutScreen() {
  useDocumentTitle("Airtime Checkout");
  const { airtimeId } = useParams();
  const {
    form: { setValue },
  } = useUtilityContent();

  useEffect(() => {
    setValue("packageId", airtimeId);
    setValue("billTypeId", TransactionType.AirtimePurchase);
    setValue("paymentMethodId", WalletType.Voucher);
  }, []);

  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: " 1fr",
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 400px ",
          },
        })}
      >
        <AirtimeFormSection />
        <PaymentSection />
      </Box>
    </Container>
  );
}

export default AirtimeCheckoutScreen;
