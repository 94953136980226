import { FormButton } from "suregifts-component-lib";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import Button from "@mui/material/Button";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import { useCartDeliveryContext } from "../delivery/useCartDeliveryContext";
import { StorageKeys } from "../../../enums";
import { useAddItem, useEditItem } from "../../../services/cart";

export const DeliveryButtons = () => {
  const { model } = useCartDeliveryContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { mutate: addItem, isLoading: isAdding } = useAddItem();
  const { mutate: editItem, isLoading: isEditing } = useEditItem(searchParams.get("id"));
  const { refetchCart } = useGlobalContext();
  const handleSubmit = () => {
    if (searchParams.get("id")) {
      editItem(model, {
        onSuccess: () => {
          toast.success("Gift item was successfully edited.");
          handleSuccess();
        },
      });
    } else {
      addItem(model, {
        onSuccess: () => {
          toast.success("Gift item was successfully added to carts.");
          handleSuccess();
        },
      });
    }
  };
  const handleSuccess = () => {
    refetchCart();
    navigate("/cart");
    sessionStorage.removeItem(StorageKeys.GIFT_PERSONALIZE);
  };
  return (
    <>
      <Button
        onClick={() => navigate(`/cart/personalize`)}
        sx={{
          borderRadius: "12px",
          padding: "15px",
          "& .MuiButton-startIcon": {
            margin: 0,
            padding: 0,
          },
        }}
        startIcon={<KeyboardArrowLeftTwoToneIcon />}
      ></Button>
      <FormButton label="Continue to Payment" onClick={handleSubmit} disabled={!model.isValid} loading={isAdding || isEditing} />
    </>
  );
};
