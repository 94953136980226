import { Typography } from "@mui/material";

type InstructionBoxProps = {
  image: string;
  title: React.ReactNode;
  info: React.ReactNode;
};
export const InstructionBox = ({ title, image, info }: InstructionBoxProps) => {
  return (
    <div>
      <img src={image} alt="" />
      <Typography sx={{ fontSize: "1.25rem", fontWeight: 700, lineHeight: "1.5rem", marginTop: "1.25rem" }}>{title}</Typography>
      <Typography sx={{ lineHeight: "1.5rem", marginTop: "0.75rem", color: "text.disabled" }}>{info}</Typography>
    </div>
  );
};
