import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { MainTable, TableBody, dateTime } from "suregifts-component-lib";
import { VoucherOperation } from "../../../../services/voucher";

type OperationViewProps = {
  operations: VoucherOperation[];
};
export const OperationView = ({ operations }: OperationViewProps) => {
  return (
    <MainTable>
      <TableHead>
        <TableRow>
          <TableCell>Date/Time</TableCell>
          <TableCell>Narration</TableCell>
          <TableCell>Fee</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {operations.map((item) => (
          <TableRow key={item.narration}>
            <TableCell>{dateTime(item.date)}</TableCell>
            <TableCell>{item.narration}</TableCell>
            <TableCell>{item.fee || "-"}</TableCell>
            <TableCell>{item.amount || "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MainTable>
  );
};
