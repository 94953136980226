import { Box, Container, useTheme } from "@mui/material";
import { useState } from "react";
import { FilterDropdown, FilterElement, Pagination, useDocumentTitle, CustomSearchInput } from "suregifts-component-lib";

import { NavTitle } from "../utility/airtime/list/AirtimeListScreen";
import { StoreLocatorData, StoreLocatorFilter, useGetStates, useGetStoreLocations } from "../../services/merchant";
import { StoreLocationCard } from "./StoreLocationCard";
import LocationDialog from "./LocationDialog";

function MerchantLocationScreen() {
  useDocumentTitle("Merchant Locations");
  const [filter, setFilter] = useState({ countryId: 1, page: 1, size: 6 } as StoreLocatorFilter);
  const theme = useTheme();
  const { data: states } = useGetStates(filter.countryId);
  const { data, isLoading } = useGetStoreLocations(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const [openDialog, setOpenDialog] = useState<StoreLocatorData | undefined>();
  return (
    <Container sx={{ mt: "60px" }}>
      <span style={{ fontSize: "30px", fontWeight: 700, lineHeight: "130%" }}>Merchant Location</span>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          "& .nav": {
            display: "none",
          },
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "250px 1fr",
            "& .nav": {
              display: "block !important",
            },
          },
        }}
      >
        <Box className="nav">
          <div style={{ display: "grid", gap: "40px", marginTop: "30px" }}>
            <div>
              <NavTitle title={"Countries"} />
              <FilterElement
                render={() => (
                  <FilterDropdown
                    onChange={(index, value) => handleFilter({ countryId: value, page: 1 })}
                    fullWidth
                    value={filter.countryId}
                    options={[
                      { text: "Nigeria", value: 1 },
                      { text: "Kenya", value: 2 },
                    ]}
                    display={"Country"}
                    variant="contained"
                    color="primary"
                    colorLevel="dark"
                  />
                )}
              />
            </div>
            <div>
              <NavTitle title={"Search"} />
              <CustomSearchInput
                onChange={(e) => handleFilter({ search: e, page: 1 })}
                sx={{
                  backgroundColor: "primary.main",
                  "& input::placeholder": {
                    color: "text.primary",
                    opacity: 1,
                  },
                }}
                placeholder="Search here... "
              />
            </div>
            <div>
              <NavTitle title={"States"} />
              <FilterElement
                render={() => (
                  <FilterDropdown
                    onChange={(index, value) => handleFilter({ stateId: value, page: 1 })}
                    fullWidth
                    value={filter.stateId}
                    options={[{ text: "--Select--" }, ...states!.map((x) => ({ text: x.name, value: x.id }))]}
                    display={"State"}
                    variant="contained"
                    color="primary"
                    colorLevel="dark"
                  />
                )}
              />
            </div>
          </div>
        </Box>
        <div>
          <Box
            sx={{
              display: "grid",
              marginTop: "1.875rem",
              rowGap: "50px",
              columnGap: "30px",
              [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(3,1fr)",
              },
            }}
          >
            {data?.items.map((item) => (
              <StoreLocationCard key={item.id} data={item} onClick={() => setOpenDialog(item)} />
            ))}
          </Box>
          <div style={{ marginTop: "1.25rem" }}>
            <Pagination
              align="center"
              size={filter.size}
              page={filter.page}
              total={data?.total}
              onChange={(page) => {
                handleFilter({ page });
              }}
            />
          </div>
        </div>
      </Box>
      {openDialog && <LocationDialog merchant={openDialog} handleClose={() => setOpenDialog(undefined)} />}
    </Container>
  );
}

export default MerchantLocationScreen;
