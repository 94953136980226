import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormButton, FormInput, PageHeaderTitle, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ChangePasswordModel, useChangePassword } from "../../../services/auth";
const schema = yup
  .object<YupShape<ChangePasswordModel>>({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
  })
  .required();
export const ChangePasswordSection = () => {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ChangePasswordModel>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const { mutate: changePassword, isLoading } = useChangePassword();
  const submitForm = (model) => {
    changePassword(model, {
      onSuccess: () => {
        toast.success("Password changed successfully.");
        reset();
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <PageHeaderTitle title="Password Settings" />
      <Stack spacing={3} sx={{ paddingTop: "30px" }}>
        <FormInput required label="Current Password" type="password" {...register("currentPassword")} helperText={errors.currentPassword?.message} />

        <Stack spacing={2} direction="row">
          <FormInput required label="New Password" type="password" {...register("newPassword")} helperText={errors.newPassword?.message} />
          <FormInput required label="Confirm Password" type="password" {...register("confirmPassword")} helperText={errors.confirmPassword?.message} />
        </Stack>
        <FormButton label="Change Password" loading={isLoading} disabled={!isValid} type="submit" sx={{ alignSelf: "flex-start" }} />
      </Stack>
    </form>
  );
};
