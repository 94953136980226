import React, { ReactNode } from "react";
import { Container, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

type MessageProps = {
  header: React.ReactNode;
  note?: string;
  messages: React.ReactNode[];
  note2?: ReactNode;
};
type TopicProps = {
  topic: string;
  subTopics: MessageProps[];
};
const topics: TopicProps[] = [
  {
    topic: "FAQ",
    subTopics: [
      {
        header: "How do I create an account?",
        messages: ["Visit the SureGifts website- suregifts.com.ng", "Click on Sign up", 'Create a new account and click on "sign in"'],
      },
      {
        header: "How can I add a voucher?",
        messages: [
          "Log in to the SureGifts website",
          "Click on your profile & click on “My Account”",
          "Click on voucher services and select “swap voucher”",
          "Click on “my vouchers”, and select “add voucher”",
          "Enter the voucher and security code and save.",
        ],
      },
      { header: "How to create a voucher PIN", messages: ["Log in to the SureGifts website", "Click on your profile & click on “Account Settings”", "Go to “Create PIN” and enter your unique PIN", "Confirm the PIN and submit"] },
      {
        header: <SubTopicHeadView title="Can I change my voucher PIN?" />,
        note: "Yes. To change your voucher PIN, follow these steps:",
        messages: ["Log in to the SureGifts website", "Click on your profile & click on “Account Settings”", "Go to “Create PIN” and enter your new PIN", "Confirm the new PIN and submit"],
      },
      {
        header: (
          <SubTopicHeadView
            title={'What is a "Split Voucher"?'}
            description={
              <div style={{}}>
                The Split Voucher function helps you divide your voucher value when you don’t want to spend the total value at once.
                <p>Please note that the service is at no extra charge. It is a free service.</p>
              </div>
            }
          />
        ),
        note: "To Split your voucher, follow these steps:",
        messages: [
          "Log in to the SureGifts website",
          "Click on your profile & click on “My Account”",
          "Click on voucher services and select “split voucher”",
          "Enter the voucher code you want to split and choose how many vouchers you would like to split the total value into",
          "Enter the desired amount for each voucher and click “split”",
          "You will receive an email notification with the split voucher codes.",
          "The split voucher codes will also be displayed in your account.",
        ],
      },
      {
        header: (
          <SubTopicHeadView
            title="What is a “Merge Voucher”?"
            description={
              <div>
                The merge voucher function helps you combine two voucher codes of the same type to become one voucher.
                <p>Please note that the service is at no extra charge. It is a free service.</p>
              </div>
            }
          />
        ),
        note: "To Merge your vouchers, follow these steps:",
        messages: [
          "Log in to the SureGifts website",
          "Click on your profile & click on “My Account”",
          "Click on voucher services and select “merge voucher”",
          "Add the voucher codes you would like to combine and click “merge”",
          "You will receive an email notification with the merged voucher codes",
          "The merged voucher codes will also be displayed in your account.",
        ],
      },
      {
        header: (
          <SubTopicHeadView
            title="What is a Swap Voucher?"
            description={
              <div>
                The swap voucher function helps you change the voucher type you currently have to a different merchant. You can change your voucher from a generic to a merchant-specific voucher, or you can swap between merchants.
                <p>Please note that a 10% swap fee will be deducted from your voucher value.</p>
              </div>
            }
          />
        ),
        note: "To Swap your voucher, follow these steps:",
        messages: [
          "Log in to the SureGifts website",
          "Click on your profile & click on “My Account”",
          "Click on voucher services and select “swap voucher”",
          "Enter the voucher code, choose your preferred merchant, and click “swap”",
          "You will receive a notification of the successful voucher swap. Your current voucher code will still be valid to redeem at the merchant store you swapped to.",
        ],
      },

      {
        header: "Release Voucher",
        note: "To release your voucher, follow these steps:",
        messages: ["Log in to your account or sign up if you don’t have an account", "Click on voucher services, click on release voucher", "Input the voucher code and create a pin to release the voucher"],
      },
      {
        header: "How can I top up my wallet?",
        messages: [
          "Log in to your account or sign up if you don’t have an account",
          "Click on Wallet under account overview",
          "Select where you want to top up your wallet ( bank transfer, credit/debit card, PayPal, Crypto, etc.) and follow the instructions.",
        ],
      },
      {
        header: "How can I check my voucher balance and history?",
        messages: ["Log in to the SureGifts website", "Click on your profile & click on “My Account”", "Click on voucher services and select “ voucher balance & history”", "Enter the Voucher and security code and click check"],
      },
      {
        header: "Can I use the balance in my wallet to purchase Airtime or Pay bills?",
        note: "Yes. To purchase airtime/ pay bills with wallet balance, follow these steps:",
        messages: [
          "Log in to the SureGifts website",
          "Click on airtime or pay bills",
          "Select the service you want",
          "Click on the wallet as the payment method",
          "Click on Pay with the wallet to complete your order",
          "Enter the voucher and security code and save.",
        ],
      },
      {
        header: "Can I top up my voucher balance?",
        messages: ["No. However, you can merge 2 vouchers together."],
      },
      {
        header: "How can I purchase a SureGifts voucher?",
        note: "To purchase a voucher, please follow these steps:",
        messages: [
          "Visit our site - https://suregifts.com.ng/",
          "Click on Login/Signup",
          "Select your preferred gift card (Please note that the SureGifts Generic gift card can be used at any of our merchants' locations)",
          "Fill in the desired amount",
          "Add a special message & preferred image",
          "Select mode of delivery - Email (electronic cards), PDF, or Home (physical cards) delivery.",
          "Input recipients' details and proceed to the checkout to make payment.",
          "Make payments from your wallet, with your credit/debit card, PayPal, or through a bank transfer.",
        ],
      },
      {
        header: "How can I buy airtime with my voucher?",
        note: "To buy airtime with your voucher:",
        messages: [
          "Kindly login/sign up on the website suregifts.com.ng",
          "Click on the 'Menu' icon.",
          "Click on 'Airtime",
          "Choose your desired service provider.",
          "Input desired amount and phone number.",
          "Proceed to payment by inputting your voucher code.",
        ],
      },
      {
        header: "How can I pay bills with my voucher?",
        note: "To pay bills with your voucher;",
        messages: [
          "Kindly login/sign up on the website suregifts.com.ng",
          "Click on the 'Menu' icon.",
          "Click on 'Pay bills",
          "Choose your desired service provider.",
          "Input desired amount, phone number, and other information required.",
          "Proceed to payment by inputting your voucher code.",
        ],
        note2: "Please note that there’s a service charge for bills payment",
      },
      {
        header: (
          <SubTopicHeadView
            title="How do I find merchants around me?"
            description={
              <div>
                To find merchant stores around you, kindly visit <br />
                <a href="https://suregifts.com.ng/Service/MerchantLocations">https://suregifts.com.ng/Service/MerchantLocations</a>
              </div>
            }
          />
        ),
        messages: [],
      },
      {
        header: "How do I use my Voucher online?",
        messages: ["Split your voucher on the SureGifts website into the amount you would like to use at the online store and the remaining amount that can be used later.", "Shop online and use the newly generated voucher code to pay at checkout."],
        note2: "N/B: When redeeming a voucher online, we have a one-time usage clause, which states that the total value of the voucher is rendered once entered into the merchant’s website.",
      },
      {
        header: "How do I redeem at a physical store?",
        messages: [
          "Choose your preferred items, while at the store",
          "Inform the cashier you would like to pay with your voucher",
          "A SureGifts POS will be provided for you to input your voucher code and pin, once successful, your voucher will be debited with the value of the items bought.",
          "Walk out with your purchased items.",
        ],
      },
      {
        header: "How long will it take to get my Voucher(s)?",
        messages: ["Delivery of physical vouchers takes 3-5 working days after payment and design confirmation.", "Delivery of electronic vouchers takes 24 hours after payment and design confirmation."],
      },
      {
        header: "How can I secure my voucher?",
        messages: [],
        note: "When you sign up on the SureGifts Website, you will be prompted to create a unique pin. This pin is unique only to you and should not be shared with anybody else. This pin will be required at the point of your voucher redemptions.",
      },
      { header: "How can I sign up as a Merchant?", messages: ["Log on to the SureGifts Website", "Click on the tab “Sign up as a merchant”", "Click on the tab “Sign up as a merchant”", "Click on Submit"] },
    ],
  },
];

function MessageSection() {
  const theme = useTheme();
  return (
    <Container>
      <Box
        sx={{
          marginTop: "25rem",
          "& .topic": {
            display: "grid",
            paddingBottom: "6.25rem",
            "&:not(:first-of-type)": {
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              paddingTop: "6.25rem",
            },
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "1fr 3fr",
            },
          },
        }}
      >
        {topics.map((topic, index) => (
          <Box className="topic" key={index}>
            <div>
              <Typography sx={{ fontSize: "1.125rem", lineHeight: "1.375rem", fontWeight: 500 }}>{topic.topic}</Typography>
            </div>
            <ul style={{ display: "grid", gap: "3rem" }}>
              {topic.subTopics.map((m, i) => (
                <li key={i}>
                  <Typography sx={{ fontSize: "1.5rem", fontWeight: 700, lineHeight: "1.75rem" }}>{m.header}</Typography>
                  <Inner2Section header={m.header} messages={m.messages} note2={m.note2} note={m.note} />
                </li>
              ))}
            </ul>
          </Box>
        ))}
      </Box>
    </Container>
  );
}
type SubTopicHeadViewProps = {
  title: string;
  description?: ReactNode;
};
function SubTopicHeadView({ title: title, description }: SubTopicHeadViewProps) {
  return (
    <Box sx={{}}>
      <div>{title}</div>
      <Box
        sx={{
          marginTop: "1rem",
          fontWeight: "normal",
          fontSize: "1rem",
          "& p": {
            margin: "0",
            marginTop: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {description}
      </Box>
    </Box>
  );
}
const Inner2Section = ({ messages, note, note2 }: MessageProps) => {
  return (
    <div>
      <div style={{ marginTop: "1rem", fontSize: "16px" }}>{note}</div>
      <Box
        component={"ol"}
        sx={{
          lineHeight: "1.875rem",
          marginTop: "1rem",
          "& li": {
            padding: "4px 0",
            fontSize: "16px",
          },
        }}
      >
        {messages.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </Box>
      <Box sx={{ marginTop: "1rem", color: "red" }}>{note2}</Box>
    </div>
  );
};
export default MessageSection;
