import { useTheme } from "@mui/material/styles";
import { LoadingBox } from "suregifts-component-lib";
import { BalanceCard } from "./BalanceCard";

function WalletInfoSection() {
  const theme = useTheme();
  return (
    <LoadingBox>
      <BalanceCard />
    </LoadingBox>
  );
}

export default WalletInfoSection;
