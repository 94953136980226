import React from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { LeftSection } from "./LeftSection";
import FormSection from "./FormSection";
import { useDocumentTitle } from "suregifts-component-lib";
import { useGetIndustries } from "../../../services/general";

function MerchantRegisterScreen() {
  const theme = useTheme();
  useDocumentTitle("Merchant Regisration");

  return (
    <Container>
      <Box
        sx={{
          marginY: "6rem",
          display: "grid",
          gap: "5rem",
          alignItems: "center",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <LeftSection />
        <FormSection />
      </Box>
    </Container>
  );
}

export default MerchantRegisterScreen;
