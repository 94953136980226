import { Badge, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { CartIcon } from "../../icons/Icons";

export const CartIconView = () => {
  const { cart } = useGlobalContext();
  return (
    <Badge badgeContent={cart?.items.length} color="error">
      <Box
        sx={{
          padding: "14px 18px",
          bgcolor: "primary.light",
          borderRadius: "18px",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "primary.main",
          },
        }}
        component={Link}
        to={"/cart"}
      >
        <CartIcon />
      </Box>
    </Badge>
  );
};
