import { useEffect, useState } from "react";
import { LoadingBox, Pagination, SearchButton, SearchContainer, SearchInput, TabController, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VoucherCard from "../VoucherCard";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { VoucherFilter, useGetVouchers } from "../../../../services/voucher";

function VoucherListScreen() {
  const [filter, setFilter] = useFilter<VoucherFilter>({ page: 1, size: 9, search: "" });

  const { data, isFetching, refetch } = useGetVouchers(filter);
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();
  const theme = useTheme();
  useDocumentTitle("My Vouchers");

  useEffect(() => {
    refetch();
  }, [pathname]);

  return (
    <div>
      <SearchContainer sx={{ flexWrap: "wrap", gap: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <TabController
          value={tabIndex}
          onChange={(value) => {
            setTabIndex(value);
            handleFilter({ active: value === 0, page: 1 });
          }}
          tabs={[
            { label: "My Vouchers", value: 0 },
            { label: "Archived", value: 1 },
          ]}
        />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            placeholder="Search Merchant"
            sx={{
              bgcolor: "primary.light",
              borderRadius: "18px",
              minWidth: "175px",
            }}
            value={filter.search}
            onChange={(value) => {
              handleFilter({ search: value, page: 1 });
            }}
          />

          <SearchButton sx={{ py: "0.125rem" }} label="Add Voucher" onClick={() => navigate("add")} />
        </Box>
      </SearchContainer>
      <LoadingBox
        loading={isFetching}
        loadingPosition="center"
        sx={{
          display: "grid",
          marginTop: "1.875rem",
          rowGap: "50px",
          columnGap: "30px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3,1fr)",
          },
        }}
      >
        {data?.items.map((item) => (
          <VoucherCard
            data={item}
            key={item.voucherCode}
            onClick={() => {
              navigate(`${item.voucherCode}`);
            }}
          />
        ))}
      </LoadingBox>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default VoucherListScreen;
