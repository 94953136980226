import { Stack, Typography } from "@mui/material";
import { FormButton, useUtilityContent } from "suregifts-component-lib";
import { useGetWalletInfo } from "../../services/transaction";

export const WalletSummaryCard = () => {
  const { data: wallet } = useGetWalletInfo();
  const {
    sending,
    submitForm,
    form: {
      formState: { isValid },
    },
  } = useUtilityContent();
  return (
    <Stack spacing={3} marginTop="30px">
      <div>
        <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>Wallet Balance</Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "36px", lineHeight: "44px", marginTop: "10px" }}>{wallet?.balanceString}</Typography>
      </div>
      <FormButton label="Pay with Wallet" type="submit" loading={sending} fullWidth disabled={(wallet?.balance || 0) <= 0 || !isValid} onClick={submitForm} />
    </Stack>
  );
};
