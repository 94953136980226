import { Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { TitleDetailView } from "suregifts-component-lib";
import { VoucherStatusLabel } from "../../../components/atoms/VoucherStatusLabel";
import { defaultStoreImage } from "../voucher/VoucherCard";
import Card from "@mui/material/Card";
import { useVoucherServiceContext } from "./VoucherServiceContext";

export const VoucherInfoView = ({}) => {
  const { voucher, isValidCode } = useVoucherServiceContext();

  const theme = useTheme();

  if (!isValidCode) return <div></div>;
  return (
    <div style={{ display: "grid", gap: "30px" }}>
      {voucher && (
        <Card
          sx={{
            gap: "40px",
            display: "grid",
            borderRadius: "30px",
            backgroundColor: "primary.main",
            padding: "40px 50px",
          }}
        >
          <Box sx={{ display: "flex", gap: "40px", flexWrap: "wrap", postion: "relative" }}>
            <Box sx={{ height: "140px", width: "240px", borderRadius: "18px", objectFit: "cover" }} component="img" src={voucher.imageUrl ?? defaultStoreImage} />
            <Box
              sx={{
                display: "grid",
                [theme.breakpoints.up("sm")]: { gridTemplateColumns: "repeat(2,1fr)" },
                [theme.breakpoints.up("md")]: { gridTemplateColumns: "repeat(3,1fr)" },
                gap: "30px",
                flexGrow: 1,
              }}
            >
              <TitleDetailView title="Voucher code" detail={voucher.voucherCode} />
              <div
                style={{
                  gridColumn: "span 2",
                }}
              >
                <TitleDetailView title="Merchant " detail={voucher.merchantName} />
              </div>
              <TitleDetailView title="Serial No" detail={voucher.serialNumber} />
              <TitleDetailView title="Starting Balance" detail={voucher.value} />
              <TitleDetailView title="Current Balance" detail={voucher.balance} />
              <TitleDetailView title="Countries" detail={voucher.countries} />
              <TitleDetailView
                title="Expire date"
                detail={
                  <Typography fontSize="inherit" color={voucher.isExpired ? "error.main" : "inherit"}>
                    {moment(voucher.expiryDate).format("DD.MM.YYYY")}
                  </Typography>
                }
              />
              <TitleDetailView title="Status" detail={<VoucherStatusLabel text={voucher.status} status={voucher.statusId} />} />
            </Box>
          </Box>
        </Card>
      )}
    </div>
  );
};
