import React, { useState } from "react";

import { Box } from "@mui/system";
import { Link } from "react-router-dom";

type SuregiftsLogoProps = {
  color?: "White" | "Red";
};
function SuregiftsLogo({ color = "White" }: SuregiftsLogoProps) {
  const [url, setUrl] = useState(color === "White" ? "/assets/images/logo.svg" : "/assets/images/logo-red.svg");
  return (
    <Link to="/">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={url} alt="" style={{ marginLeft: "0.5rem" }} className="suregifts-logo" />
      </div>
    </Link>
  );
}

export default SuregiftsLogo;
