import { Box, Container, useTheme } from "@mui/material";
import React from "react";
import { useDocumentTitle } from "suregifts-component-lib";
import FormSection from "./FormSection";
import { LeftSection } from "./LeftSection";

function ContactSupportScreen() {
  const theme = useTheme();
  useDocumentTitle("Support");
  return (
    <Container>
      <Box
        sx={{
          marginY: "6rem",
          display: "grid",
          gap: "5rem",
          justifyItems: "flex-end",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <LeftSection />
        <FormSection />
      </Box>
    </Container>
  );
}

export default ContactSupportScreen;
