import { Card, TableCell, TableHead, TableRow } from "@mui/material";
import { MainTable, OrderStatusLabel, PageFilter, Pagination, TableBody, dateOnly, useDocumentTitle, useFilter, useGetBillPayments } from "suregifts-component-lib";

import { OrderNavSection } from "./OrderNavSection";
import { dateTime } from "suregifts-component-lib";

function BillPaymentsScreen() {
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const { data: data, isFetching } = useGetBillPayments(filter);
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Bills Payments");
  return (
    <Card
      sx={{
        borderRadius: "30px",
        backgroundColor: "primary.main",
        padding: "40px 50px",
      }}
    >
      <OrderNavSection total={data?.total} />
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.narration}</TableCell>
              <TableCell>{dateTime(item.dateCreated)}</TableCell>
              <TableCell>{item.subTotal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </Card>
  );
}

export default BillPaymentsScreen;
