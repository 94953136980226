import { Typography } from "@mui/material";
import { TabController } from "suregifts-component-lib";

export const OrderNavSection = ({ total }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <TabController
        tabs={[
          { label: "Order History", value: "/orders" },
          { label: "Bills Payments", value: "/orders/bills" },
        ]}
      />

      <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "22px", color: "text.secondary" }}>{total} Orders</Typography>
    </div>
  );
};
