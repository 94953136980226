import { Box } from "@mui/material";
import { useGetGiftCardById } from "../../../../services/gift";
import { defaultStoreImage } from "../../../profile/voucher/VoucherCard";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
import { FaceLabel } from "./FaceLabel";

export const FrontView = () => {
  const { model } = useCartDeliveryContext();
  const { data: gift } = useGetGiftCardById(model?.giftId);
  return (
    <Box>
      <div style={{ overflow: "hidden", borderRadius: "12px" }}>
        <img
          src={gift?.imageUrl || defaultStoreImage}
          style={{
            display: "block",
            width: "100%",
            marginInline: "auto",
            objectFit: "cover",

            maxHeight: "180px",
            height: "180px",
          }}
        />
      </div>
      <FaceLabel text={"Front"} />
    </Box>
  );
};
