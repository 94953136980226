import { Typography, Box, Container } from "@mui/material";
import { InstructionBox } from "./InstructionBox";

export const SectionThree = () => {
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center", margin: "12rem auto" }}>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, lineHeight: "3.75rem" }}>
        How to Redeem Gift Cards
        <Typography component={"span"} sx={{ color: "error.main", font: "inherit" }}>
          Offline
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: "3.75rem", justifyContent: "center", marginTop: "5rem", flexWrap: "wrap", whiteSpace: "normal" }}>
        <InstructionBox
          image={"/assets/images/how-to-use/4.png"}
          title={"Visit Store"}
          info={
            <>
              Check though our website for physical <br />
              stores and visit it
            </>
          }
        />
        <InstructionBox
          image={"/assets/images/how-to-use/5.png"}
          title={"Select Item"}
          info={
            <>
              Go though the store and select items and <br /> add them to your cart
            </>
          }
        />
        <InstructionBox
          image={"/assets/images/how-to-use/3.png"}
          title={"Present Voucher"}
          info={
            <>
              Simply present your voucher code to them
              <br />
              they'll verify with us that it's valid, and
              <br />
              accept your code as payment
            </>
          }
        />
      </Box>
    </Container>
  );
};
