import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { FormButton } from "suregifts-component-lib";
import StaticHeader from "../../components/atoms/StaticHeader";
import StaticHeaderInfo from "../../components/atoms/StaticHeaderInfo";
import { useNavigate } from "react-router-dom";

function SectionFour() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: "10rem",
        display: "grid",
        gap: "5rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
      }}
    >
      <Box component="img" sx={{ width: "100%" }} src="/assets/images/bg/4.png" />
      <div style={{ paddingTop: "2rem", position: "relative" }}>
        <StaticHeader>Have a Product or Service? Become a Merchant</StaticHeader>
        <StaticHeaderInfo>Join over 200 merchants who have expanded their business and reached a wider audience by becoming a part of our merchant network.</StaticHeaderInfo>
        <FormButton label="Become a Merchant" sx={{ marginTop: "2.5rem" }} onClick={() => navigate("/merchant")} />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Box component={"img"} src="assets/images/bg/5.png" sx={{ width: "33%", position: "relative", bottom: "1.25rem" }} />
        </div>
      </div>
    </Box>
  );
}

export default SectionFour;
