import { Stack } from "@mui/material";
import { FormButton, FormInput, YupShape, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { VoucherTransferModel, useTransferVoucher } from "../../../../services/voucher";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { VoucherInfoView } from "../VoucherInfoView";
import VoucherServiceCard from "../VoucherServiceCard";
const schema = yup
  .object<YupShape<VoucherTransferModel>>({
    voucherCode: yup.string().required(),
    pin: yup.string().required(),
    email: yup.string().required().email(),
    name: yup.string().required(),
  })
  .required();

function VoucherSwapScreen() {
  const [searchParms, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { message } = useNotificationContext();

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isValid },
  } = useForm<VoucherTransferModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      voucherCode: searchParms.get("voucherCode") || "",
      pin: "",
      email: "",
    },
  });
  useDocumentTitle("Transfer Voucher");

  const { isLoading, mutate: transfer } = useTransferVoucher();

  const submitForm = (model: VoucherTransferModel) => {
    transfer(model, {
      onSuccess: () => {
        message({
          buttonText: "Close",
          title: "Voucher Sent",
          desc: `You have successfully sent ${watch("voucherCode")} to ${watch("name")}`,
          onClose: () => {
            navigate(`/vouchers`);
          },
        });
      },
    });
  };

  useEffect(() => {
    setSearchParams({ voucherCode: watch("voucherCode") });
  }, [watch("voucherCode")]);
  return (
    <div style={{ display: "grid", gap: "30px" }}>
      <VoucherInfoView />
      <VoucherServiceCard title={"Transfer Voucher"}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <FormInput charLength={16} value={watch("voucherCode")} isNumber label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
            <FormInput value={watch("pin")} label="Security Code" {...register("pin")} type="password" isNumber charLength={6} helperText={errors.pin?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput label="Recipient's Email" {...register("email")} helperText={errors.email?.message} />
            <FormInput label="Recipient's Name" {...register("name")} helperText={errors.name?.message} />
          </Stack>
          <FormButton label="Transfer" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{ alignSelf: "flex-start" }} />
        </Stack>
      </VoucherServiceCard>
    </div>
  );
}

export default VoucherSwapScreen;
