import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useMemo } from "react";
import { DialogTitle, FormButton, FormInput, YupShape, getPaymentPath, useAuthContext } from "suregifts-component-lib";
import { useWalletContext } from "./WalletContext";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FundingModel, useFundWallet } from "../../../services/transaction";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object<YupShape<FundingModel>>({
    amount: yup.number().required(),
  })
  .required();

function FundingModal() {
  const { state, dispatch } = useWalletContext();
  const { user } = useAuthContext();
  const localUser = user;
  const handleClose = () => {
    dispatch({ type: "Main" });
  };
  const navigate = useNavigate();

  const { mutate: fundWallet, isLoading } = useFundWallet();
  const returnPath = useMemo(() => getPaymentPath(state.paymentMethod!.id), [state.paymentMethod]);

  const { register, handleSubmit, watch } = useForm<FundingModel>({
    defaultValues: {
      paymentMethod: state.paymentMethod?.id,
      returnPath: `funding_callback/${returnPath}`,
      amount: "",
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    fundWallet(data, {
      onSuccess: (result) => {
        window.location.href = result.paymentLink;
      },
      onError: (e) => {
        if (e.error === "UnverifiedUser") {
          handleClose();
          navigate("/account");
        }
      },
    });
  };

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title={`${state.paymentMethod?.name} Payment`} handleClose={handleClose} />
      <DialogContent>
        <FormInput
          {...register("amount")}
          label="Enter Amount"
          isNumber
          value={watch("amount")}
          startAdornment={
            <Box component={"span"} sx={{ p: "0.25rem", backgroundColor: "primary.light", borderRadius: "0.1875rem" }}>
              {localUser?.currency}
            </Box>
          }
        />
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth loading={isLoading} label={`Pay with ${state.paymentMethod?.name}`} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default FundingModal;
