import { Typography } from "@mui/material";

export const RightTop = () => {
  return (
    <div style={{ padding: "70px 21px", textAlign: "left" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700, marginLeft: "20px" }}>Redemption Details</Typography>
      <ul style={{ paddingInline: "20px", fontSize: "14px", display: "grid", gap: "10px" }}>
        <li> This Gift Card is rdeemable at any of our Retail Partners.</li>
        <li>Receipient will settle the merchant in cash of any value spent exceeding the gift card value.</li>
        <li>Gift Card also redeemable for bill payments. Visit http://www.suregifts.com.ng/pay</li>
        <li>
          Kindly visit <span style={{ fontStyle: "italic", fontWeight: 600 }}>http://www.suregifts.com.ng/Service/MerchantLocations</span> to see the list of all our partner merchants.
        </li>
        <li>Please note that voucher value can only be used ONCE at supported online stores.</li>
        <li>This voucher is valid for one year.</li>
      </ul>
    </div>
  );
};
