import { Add } from "@mui/icons-material";
import { Typography, Button, Box } from "@mui/material";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import EventAddEditForm from "./EventAddEditModal";
import { v4 as uuidv4 } from "uuid";
import { useContactContext } from "../ContactContext";
import { EventListItem } from "./EventListItem";
import { ContactModel, EventModel } from "../../../../services/contact";

type EventSectionProps = {
  watch: UseFormWatch<ContactModel>;
  setValue: UseFormSetValue<ContactModel>;
};
export const EventSection = ({ watch, setValue }: EventSectionProps) => {
  const { state, dispatch } = useContactContext();

  const handleSaveEvent = (model: EventModel) => {
    var events = [...watch("events")];
    if (model.id) {
      const index = events.findIndex((x) => x.id === model.id);
      if (index !== -1) {
        events[index] = model;
      }
    } else {
      events.push({ ...model, id: uuidv4() });
    }
    setValue("events", events);
    dispatch({ type: "Main" });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "45px" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: 700, lineHeight: "29px" }}>New Contact Information</Typography>
        <Button startIcon={<Add />} color="success" variant="text" onClick={() => dispatch({ type: "AddEvent" })}>
          New Events
        </Button>
      </div>
      <Box sx={{ display: "grid", gap: "20px", marginTop: "30px" }}>
        {watch("events").map((item, i) => (
          <EventListItem
            key={item.id}
            data={item}
            onDeleteClick={() => {
              const events = [...watch("events")];
              events.splice(i, 1);
              setValue("events", events);
            }}
            onEditClick={() => {
              dispatch({ type: "EditEvent", param: item });
            }}
          />
        ))}
      </Box>
      {(state.pageType === "AddEvent" || state.pageType === "EditEvent") && (
        <EventAddEditForm
          handleClose={() => {
            dispatch({ type: "Main" });
          }}
          onSave={handleSaveEvent}
        />
      )}
    </>
  );
};
