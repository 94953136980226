import { Tabs, Tab, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import EmailTabPanel from "./email/EmailTabPanel";

import { DeliveryMediumType, TabPanel } from "suregifts-component-lib";
import HomeDeliveryTabPanel from "./home-delivery/HomeDeliveryTabPanel";
import PdfDeliveryPanel from "./pdf/PdfDeliveryPanel";
import { useCartDeliveryContext } from "./useCartDeliveryContext";
import { CartJourneySection } from "../CartJourneySection/CartJourneySection";

function CartDeliveryScreen() {
  const theme = useTheme();
  const { model, updateModel } = useCartDeliveryContext();

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Box
        sx={{
          display: "grid",
          gap: "20px",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "250px minmax(772px, 60%)",
          },
        }}
      >
        <div></div>
        <div>
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Delivery Method</Typography>
          <Tabs
            TabIndicatorProps={{ hidden: true }}
            value={model.deliveryMedium}
            onChange={(e, newValue) => {
              updateModel({ deliveryMedium: newValue });
            }}
            sx={{
              marginTop: "20px",
              "& .MuiTabs-flexContainer": {
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(3,1fr)",
              },
              "& .MuiTab-root": {
                fontWeight: 500,
                fontSize: "16px",
                borderRadius: "16px",
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                paddingY: "16px ",
                "&.Mui-selected": {
                  backgroundColor: "success.main",
                  color: "success.contrastText",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "28px",
                },
              },
              marginBottom: "1.875rem",
            }}
          >
            <Tab icon={<EmailIcon />} iconPosition="start" label="Send via Email" value={DeliveryMediumType.Email} />
            {/* <Tab label="Print at Home" value={DeliveryMediumType.Pdf} icon={<LocalPrintshopIcon />} iconPosition="start" /> */}
            <Tab label="Home Delivery" value={DeliveryMediumType.HomeDelivery} icon={<TimeToLeaveIcon />} iconPosition="start" />
          </Tabs>
          <TabPanel index={DeliveryMediumType.Email} value={model.deliveryMedium}>
            <EmailTabPanel />
          </TabPanel>
          <TabPanel index={DeliveryMediumType.Pdf} value={model.deliveryMedium}>
            <PdfDeliveryPanel />
          </TabPanel>
          <TabPanel index={DeliveryMediumType.HomeDelivery} value={model.deliveryMedium}>
            <HomeDeliveryTabPanel />
          </TabPanel>
        </div>
      </Box>
      <CartJourneySection activeStep={1} model={model} />
    </Container>
  );
}

export default CartDeliveryScreen;
