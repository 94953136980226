import { Box, Container, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const SectionOne = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Container>
      <Box
        sx={{
          marginTop: "10rem",
          display: "grid",
          gap: "5rem",
          alignItems: "center",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <div style={{ paddingTop: "2rem" }}>
          <Typography sx={{ fontSize: "5rem", fontWeight: 700, lineHeight: "120%" }}>How To Use Gift Cards</Typography>

          <FormButton
            label="Contact us"
            sx={{ marginTop: "3rem", justifyContent: "flex-start", padding: "2rem 10rem 2rem 2rem", borderRadius: "1.25rem", paddingRight: "10rem", fontSize: "1rem", fontWeight: 700 }}
            onClick={() => navigate("/contact-support")}
          />
        </div>
        <Box component="img" sx={{ width: "100%" }} src="/assets/images/bg/6.png" />
      </Box>
    </Container>
  );
};
