import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape, useAuthContext } from "suregifts-component-lib";
import { Stack } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginModel, ProfileInfo, useLogin } from "../services/auth";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AuthDialog } from "../components/atoms/AuthDialog";
import { useEffect } from "react";
export declare var grecaptcha: any;
const schema = yup
  .object<YupShape<LoginModel>>({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();
function LoginModal() {
  const location = useLocation();
  const { user, saveToken, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };

  const { mutate: login, isLoading } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginModel>({
    resolver: yupResolver(schema),
  });
  const submitForm = (model: LoginModel) => {
    login(model, {
      onSuccess: (data) => {
        console.log(data);

        toast.success("Login successful.");
        saveToken(data.token, data.refreshToken, { ...user, ...data });
      },
    });
  };
  useEffect(() => {
    if (isAuthenticated) {
      const localUser = user as ProfileInfo;
      if (!localUser.emailConfirmed) {
        navigate("/verify-email");
        return;
      }
      if (!localUser.phoneNumberConfirmed) {
        navigate("/verify-phone");
        return;
      }
      navigate(location.state?.from?.pathname || "/account");
    }
  }, [user]);
  return (
    <AuthDialog open maxWidth="xs">
      <DialogTitle title="Welcome back, sign in" handleClose={handleClose} />
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={2}>
            <FormInput required label="Email" {...register("email")} helperText={errors.email?.message} />
            <FormInput required label="Password" {...register("password")} type="password" helperText={errors.password?.message} />
          </Stack>

          <div style={{ textAlign: "right" }}>
            <Button variant="text" color="success" sx={{ padding: "0" }} onClick={() => navigate("/forgot-password")}>
              Forgot your password?
            </Button>
          </div>
          <FormButton loading={isLoading} type="submit" label="Sign in" fullWidth disabled={!isValid} />
        </form>
      </DialogContent>
      <DialogActions>
        <Typography>Don't have account? </Typography>
        <Button variant="text" color="success" sx={{ padding: 0 }} onClick={() => navigate("/register")}>
          Sign Up
        </Button>
      </DialogActions>
    </AuthDialog>
  );
}

export default LoginModal;
