import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useAccountContext } from "./AccountContext";
import { TypographyProps } from "@mui/system";
import moment from "moment";
import { formatMoney, useAuthContext } from "suregifts-component-lib";

function SectionOne() {
  const { profile } = useAccountContext();
  const { user } = useAuthContext();
  return (
    <Card sx={{ borderRadius: "30px", padding: "42px 30px", backgroundColor: "primary.main", display: "flex", justifyContent: "space-between", alignItems: "cetner" }}>
      <InfoBox top={moment().format("DD MMMM YYYY")} bottom={`Hello ${profile?.profileInfo.firstName}`} />
      <div style={{ display: "flex", gap: "2rem" }}>
        <InfoBox textAlign={"right"} top={"Wallet Balance:"} bottom={formatMoney(profile?.walletBalance, user?.currency)} />
        <Box component={"img"} src="/assets/images/wallet.png" />
      </div>
    </Card>
  );
}
type InfoBoxProps = {
  top?: string;
  bottom?: string;
  textAlign?: TypographyProps["textAlign"];
};
const InfoBox = ({ top, bottom, textAlign = "left" }: InfoBoxProps) => {
  return (
    <div>
      <Typography textAlign={textAlign} sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "17px", color: "text.secondary" }}>
        {top}
      </Typography>
      <Typography textAlign={textAlign} sx={{ fontSize: "24px", fontWeight: 700, lineHeight: "29px", marginTop: "10px" }}>
        {bottom}
      </Typography>
    </div>
  );
};
export default SectionOne;
