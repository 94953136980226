import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GreetingCardItem, useGetGreetingCards } from "../../../services/gift";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../enums";
import { AddItemModel } from "../../../services/cart";

import { DeliveryMediumType, useDocumentTitle } from "suregifts-component-lib";
import CartDeliveryScreen from "./CartDeliveryScreen";

type CartDeliveryContextProps = {
  model: AddItemModel;
  updateModel: (params: any) => void;
};
const CartDeliveryContext = createContext({} as CartDeliveryContextProps);

export const useCartDeliveryContext = () => useContext(CartDeliveryContext);

export const CartDeliveryContextProvider = ({}) => {
  const navigate = useNavigate();
  useDocumentTitle("Cart");
  useEffect(() => {
    var data = sessionStorage.getItem(StorageKeys.GIFT_PERSONALIZE);

    if (!data) {
      navigate("/gift-cards");
      return;
    }
    var result = JSON.parse(data) as AddItemModel;

    if (!result.giftId || result.amount <= 0) {
      navigate("/gift-cards");
    } else if (!result.bgPicture) {
      navigate("/cart/personalize");
    }
  }, []);

  const [model, setModel] = useState<AddItemModel>(() => {
    var data = sessionStorage.getItem(StorageKeys.GIFT_PERSONALIZE);

    if (!data) {
      return {} as AddItemModel;
    }
    var result = JSON.parse(data) as AddItemModel;
    if (!result.deliveryMedium) result.deliveryMedium = DeliveryMediumType.Email;
    return result;
  });

  return (
    <CartDeliveryContext.Provider
      value={{
        model,
        updateModel: (params: any) => {
          setModel((prev) => {
            var result = { ...prev, ...params };
            sessionStorage.setItem(StorageKeys.GIFT_PERSONALIZE, JSON.stringify(result));
            return result;
          });
        },
      }}
    >
      <CartDeliveryScreen />
    </CartDeliveryContext.Provider>
  );
};
