import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
type NavItemProps = {
  name: string;
  onClick: () => void;
  selected: boolean;
};
export const NavItem = ({ name, onClick, selected }: NavItemProps) => {
  return (
    <Typography sx={{ cursor: "pointer", color: selected ? "text.primary" : "text.disabled", display: "flex", justifyContent: "space-between" }} onClick={onClick}>
      {name} {selected && <CheckIcon sx={{ fontSize: "24px" }} />}
    </Typography>
  );
};
