import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, useAuthContext, OrderStatusType, WalletType, TransactionStatusType, NameAndId, formatMoney, DeliveryMediumType } from "suregifts-component-lib";

export const useGetCart = () => {
  const axios = useAxios();
  const { isAuthenticated } = useAuthContext();
  return useQuery<CartData, ServerError>({
    queryKey: ["cart"],
    keepPreviousData: true,
    enabled: isAuthenticated,
    refetchInterval: 60000,
    queryFn: () => axios.get("/orders/api/cart", {}),
  });
};
export const useGetDeliveryStates = () => {
  const axios = useAxios();
  const { user } = useAuthContext();
  return useQuery<DeliveryStateDto[], ServerError>({
    queryKey: ["delivery-states"],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => axios.get("/orders/api/deliver-states", {}),
  });
};
export const useAddItem = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddItemModel>((model) => {
    return axios.post(`/orders/api/cart-items`, model);
  });
};
export const useEditItem = (id: any) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddItemModel>((model) => {
    return axios.post(`/orders/api/cart-items/${id}`, model);
  });
};
export const useCloneItem = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, number>((itemId) => {
    return axios.post(`/orders/api/cart-items/${itemId}/clone`);
  });
};
export const useRemoveItem = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, number>((itemId) => {
    return axios.delete(`/orders/api/cart-items/${itemId}`);
  });
};
export const useCheckout = () => {
  var axios = useAxios();
  return useMutation<CheckoutResult, ServerError, CheckoutModel>((model) => {
    return axios.post(`/orders/api/cart-chechout`, model);
  });
};
export const useApplyCoupon = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, ApplyCouponModel>((model) => {
    return axios.post(`/orders/api/cart-coupon`, model);
  });
};
export const useRemoveCoupon = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, unknown>((data) => {
    return axios.delete(`/orders/api/cart-coupon`);
  });
};
export const useReaddRecipient = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, any>((itemId) => {
    return axios.post(`/orders/api/cart-items/${itemId}/readd`);
  });
};
export const useReaddOrder = () => {
  var axios = useAxios();
  return useMutation<CartData, ServerError, any>((orderId) => {
    return axios.post(`/orders/api/cart/${orderId}/readd`);
  });
};
export interface CheckoutResult {
  orderStatus: string;
  orderStatusId: OrderStatusType;
  orderId: number;
  amountPaid: number;
  outstanding: string;
  paymentLink: string;
  paymentMethodId: WalletType;
  paymentMethod: string;
  paymentStatusId: TransactionStatusType;
  paymentStatus: string;
  invoiceNumber: string;
}

export interface ApplyCouponModel {
  couponCode: string;
}
export interface CheckoutModel {
  useWallet: boolean;
  paymentMethod?: WalletType;
  returnPath: string;
}
export interface DeliveryStateDto {
  state: string;
  id: number;
  amount: number;
}

export interface CartData {
  fee: string;
  items: CartItemDto[];
  hasCoupon: boolean;
  subTotal: string;
  shipping: string;
  discount: string;
  amountDue: string;
  coupon: string;
  printingFee: string;
}

export interface CartItemDto {
  greetingCardId: number;
  message: string;
  picture: string;
  bgPicture: string;
  printingFee: number;
  deliveryMediumId: number;
  deliveryMedium: string;
  address: string;
  city: string;
  stateId: string;
  state: string;
  id: number;
  giftId: string;
  dateCreated: string;
  bonus: string;
  value: string;
  imageUrl: string;
  statusId: number;
  status: string;
  title: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}
export interface AddItemModel {
  amount: number;
  firstName: string;
  email: string;
  phoneNumber: string;
  deliveryMedium: DeliveryMediumType;
  lastName: string;
  address: string;
  city: string;
  stateId: string;
  greetingCardId?: number;
  greetingCardCategoryId?: any;
  picture: string;
  message: string;
  bgPicture: string;
  giftId: string;
  isValid: boolean;
}
