import { createContext, useContext } from "react";
import AccountSettingScreen from "./AccountSettingScreen";
import { useDocumentTitle } from "suregifts-component-lib";
import { ProfileInfo, useGetProfile } from "../../../services/auth";
type SettingState = {
  profile?: ProfileInfo;
  // reload: () => void;
};
const AccountSettingContext = createContext({} as SettingState);
export const useAccountSettingContext = () => useContext(AccountSettingContext);
export const AccountSettingContextProvider = () => {
  const { data, refetch } = useGetProfile();
  useDocumentTitle("Account Settings");
  return (
    <AccountSettingContext.Provider
      value={{
        profile: data?.profileInfo,
        // reload: refetch,
      }}
    >
      <AccountSettingScreen />
    </AccountSettingContext.Provider>
  );
};
