import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { FormButton, FormInput, FormSelect, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { VoucherSplitModel, useSplitVoucher } from "../../../../services/voucher";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { VoucherInfoView } from "../VoucherInfoView";
import VoucherServiceCard from "../VoucherServiceCard";
import { useEffect } from "react";

const VoucherSplitScreen = () => {
  const [searchParms, setSearchParams] = useSearchParams();
  const { message } = useNotificationContext();
  const navigate = useNavigate();

  useDocumentTitle("Split Voucher");
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { isValid },
  } = useForm<VoucherSplitModel>({
    defaultValues: {
      values: ["0", "0"],
      voucherCode: searchParms.get("voucherCode") || "",
    },
  });

  const { isLoading, mutate: splitVoucher } = useSplitVoucher();
  const submitForm = (model: VoucherSplitModel) => {
    if (model.values.filter((x) => parseFloat(x) <= 0).length > 0) {
      toast.info("All new voucher values must be greater than zero.");
      return;
    }

    splitVoucher(model, {
      onSuccess: () => {
        message({
          buttonText: "Close",
          title: "Split  Successful",
          desc: "You have successfully splitted vouchers",
          onClose: () => {
            navigate("/vouchers");
          },
        });
      },
    });
  };

  const handleNumberChange = (numberOfVouchers) => {
    setValue(
      "values",
      range(1, parseInt(numberOfVouchers)).map(() => "0")
    );
  };

  const handleValueChanged = (index, value) => {
    var values = watch("values");
    values[index] = value;
    setValue("values", values);
  };
  useEffect(() => {
    setSearchParams({ voucherCode: watch("voucherCode") });
  }, [watch("voucherCode")]);

  return (
    <div style={{ display: "grid", gap: "30px" }}>
      <VoucherInfoView />
      <VoucherServiceCard title={"Split Voucher"}>
        <Stack spacing={3} sx={{}}>
          <Box display={"grid"} sx={{ gap: "30px", gridTemplateColumns: "1fr 1fr" }}>
            <div style={{ gridColumn: "span 2" }}>
              <FormInput label="Voucher Code" {...register("voucherCode")} />
            </div>
            <FormSelect
              label="Number of Vouchers"
              value={watch("values").length}
              onChange={(e) => handleNumberChange(e.target.value)}
              options={[
                { id: 2, name: 2 },
                { id: 3, name: 3 },
                { id: 4, name: 4 },
              ]}
            />
            {range(1, watch("values").length).map((item, i) => (
              <FormInput key={`values.${i}`} label={`Value ${i + 1}`} value={watch("values")[i]} onChange={(e) => handleValueChanged(i, e.target.value)} />
            ))}
          </Box>
          <FormButton label="Split" loading={isLoading} disabled={!isValid} onClick={handleSubmit(submitForm)} sx={{ alignSelf: "flex-start", width: "20%" }} />
        </Stack>
      </VoucherServiceCard>
    </div>
  );
};

function range(start, end) {
  var foo: any[] = [];
  for (var i = start; i <= end; i++) {
    foo.push(i);
  }
  return foo;
}

export default VoucherSplitScreen;
