import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AddVoucherModel, useAddVoucher } from "../../../../services/voucher";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object<YupShape<AddVoucherModel>>({
    voucherCode: yup.string().required(),
    pin: yup.string().required().min(4).max(6),
  })
  .required();

const AddVoucherModal = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddVoucherModel>({
    resolver: yupResolver(schema),
  });
  const { mutate: addVoucher, isLoading } = useAddVoucher();
  const submitForm = (model) => {
    addVoucher(model, {
      onSuccess: () => {
        toast.success("Voucher added successfully.");
        handleClose();
      },
    });
  };
  const handleClose = () => {
    navigate("/vouchers");
  };
  return (
    <Dialog open>
      <DialogTitle title="Add a New Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2} direction="row">
          <FormInput label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
          <FormInput label="Security Code" {...register("pin")} helperText={errors.pin?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} label="Save New Voucher" fullWidth disabled={!isValid} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
};

export default AddVoucherModal;
