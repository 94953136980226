import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, NameAndId, getCaptchaToken } from "suregifts-component-lib";

export const useContactSupport = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ContactSupportModel>(async (model) => {
    return axios.post("/general/api/enquiries", model, {
      headers: { Captcha: await getCaptchaToken() },
    });
  });
};
export const useGetInitialData = () => {
  const axios = useAxios(false);
  return useMutation<initialData, ServerError, unknown>((d) => {
    return axios.get("/general/api/initial-settings");
  });
};
export const useGetIndustries = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["industries"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/general/api/industries", {}),
  });
};

export interface ContactSupportModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}
export interface initialData {
  businessUrl: string;
  countryCode: string;
  contactPhone: string;
  contactEmail: string;
  currency: string;
  countries: CountryData[];
}
export interface CountryData {
  iso: string;
  name: string;
  url: string;
}
