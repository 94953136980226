import FooterSection from "./footer/FooterSection";
import HeaderSection from "./header/HeaderSection";
import MainSection from "./MainSection";
import { Box, SxProps } from "@mui/material";
import { CookieNoticeView } from "./CookieNoticeView";
import { useDocumentTitle } from "suregifts-component-lib";
const rootStyles: SxProps = {
  height: "100vh",
  display: "grid",
  gridTemplateRows: "min-content auto min-content",
  boxSizing: "border-box",
};
function LayoutContainer() {
  return (
    <Box sx={rootStyles}>
      <HeaderSection />
      <MainSection />
      <FooterSection />
      <CookieNoticeView />
    </Box>
  );
}

export default LayoutContainer;
