import { Typography, Box, useTheme, Container } from "@mui/material";
import { BlogCard } from "../blogs/BlogCard";

export const MoreBlockSection = () => {
  const theme = useTheme();
  return (
    <Container>
      <div style={{ marginTop: "7.5rem" }}>
        <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, lineHeight: "130%", textAlign: "center" }}>More News</Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              marginTop: "5rem",
              display: "grid",
              gap: "1.25rem",

              [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(3,1fr)",
              },
            }}
          >
            {[1, 2, 3].map((item, i) => (
              <BlogCard
                blogId={i}
                key={Math.random()}
                title={"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit "}
                date={new Date(2022, Math.floor(Math.random() * 11) + 1, Math.floor(Math.random() * 28) + 1).toISOString()}
                image={`/assets/images/dummy/${i % 3}.png`}
              />
            ))}
          </Box>
        </div>
      </div>
    </Container>
  );
};
