import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Dialog } from "@mui/material";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { YupShape, FormInput, FormSelect, DatePicker, FormButton, DialogTitle } from "suregifts-component-lib";
import { CheckoutResult } from "../../../../services/cart";
import { ConfirmBankTransferModel, useGetBankAccounts, useConfirmBankTransfer } from "../../../../services/transaction";
import * as yup from "yup";

type ConfirmBankTransferViewProps = {
  invoiceNumber: string;
  handleClose: () => void;
  onSubmit: () => void;
};
const schema = yup
  .object<YupShape<ConfirmBankTransferModel>>({
    amount: yup.string().required(),
    accountUsed: yup.string().required(),
    depositorName: yup.string().required(),
    date: yup.date().required(),
    referenceNumber: yup.string().required(),
  })
  .required();
export const ConfirmBankTransferView = ({ invoiceNumber, handleClose, onSubmit }: ConfirmBankTransferViewProps) => {
  const { data: accounts } = useGetBankAccounts();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<ConfirmBankTransferModel>({
    defaultValues: {
      invoiceNumber: invoiceNumber,
      accountUsed: "",
      date: moment().toISOString(),
      amount: "",
    },
    resolver: yupResolver(schema),
  });
  const { mutate: confirmPayment, isLoading } = useConfirmBankTransfer();
  const submitForm = (model) => {
    confirmPayment(model, {
      onSuccess: () => {
        toast.success("Payment details received successfully.");
        onSubmit();
      },
    });
  };

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      sx={{
        paddingBottom: "0",
        "&  h2.MuiDialogTitle-root": {
          marginBottom: "0",
        },
      }}
    >
      <DialogTitle title=" Confirm Payment" handleClose={handleClose} />
      <Stack spacing={2} sx={{}}>
        <p style={{ fontSize: "14px" }}>Note: Bank Transfer orders will be delivered once payment has been confirmed</p>
        <FormInput label="Depositor's Name" {...register("depositorName")} helperText={errors.depositorName?.message} />
        <Stack direction={"row"} spacing={2}>
          <Controller control={control} name="accountUsed" render={({ field }) => <FormSelect options={accounts?.map((x) => ({ name: x.bankName, id: x.accountNumber }))} label="Bank Name" {...field} helperText={errors.accountUsed?.message} />} />
        </Stack>
        <FormInput label="Reference Number" {...register("referenceNumber")} helperText={errors.referenceNumber?.message} />
        <Stack direction={"row"} spacing={2}>
          <FormInput isNumber value={watch("amount")} label="Amount Paid" {...register("amount")} helperText={errors.amount?.message} />
          <Controller control={control} name="date" render={({ field: { value, onChange } }) => <DatePicker label={"Date"} value={value} onChange={onChange} helperText={errors.date?.message} />} />
        </Stack>
        <FormButton loading={isLoading} onClick={handleSubmit(submitForm)} disabled={!isValid} />
      </Stack>
    </Dialog>
  );
};
