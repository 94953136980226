import { Box, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

type BlogCardProps = {
  title;
  date;
  image?;
  blogId: any;
};
export const BlogCard = ({ title, date, image, blogId }: BlogCardProps) => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        height: "27.5rem",
        //width: "24.6rem",
        borderRadius: "2.5rem",
        overflow: "hidden",
        textDecoration: "none",
        color: "inherit",
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.light",
          transform: "scale(1.01)",
        },
      }}
      to={`/blogs/${blogId}`}
      component={Link}
    >
      <img src={image} alt="" style={{ height: "15.625rem", width: "100%" }} />
      <div style={{ padding: "1.875rem", display: "grid", gap: "2.5rem", gridTemplateRows: "3.75rem min-content" }}>
        <Typography sx={{ fontSize: "1.125rem", fontWeight: 500, lineHeight: "1.875rem", overflow: "hidden" }}>{title}</Typography>
        <Typography sx={{ fontSize: "1rem", fontWeight: 500, lineHeight: "1.25rem", color: "success.main" }}>{moment(date).format("MMM, Do YYYY")}</Typography>
      </div>
    </Box>
  );
};
