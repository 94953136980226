import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useAccountContext } from "./AccountContext";
import { SummarySection } from "./SummarySection";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import PhoneVerificationModal from "../../../modals/PhoneVerificationModal";
import { Outlet } from "react-router-dom";
import { LoadingBox, useDocumentTitle } from "suregifts-component-lib";
import { useEffect } from "react";

function AccountScreen() {
  useDocumentTitle("Account Overview");
  const { profile, refetch } = useAccountContext();
  useEffect(() => {
    if (!profile) {
      refetch();
    }
  }, [profile]);

  if (!profile) return <LoadingBox sx={{ marginTop: "40px" }} loading />;
  return (
    <Box
      sx={{
        display: "grid",
        gap: "20px",
      }}
    >
      <SectionOne />
      <SummarySection />
      <SectionThree />
      <Outlet />
    </Box>
  );
}

export default AccountScreen;
