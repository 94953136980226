import { Box, useTheme } from "@mui/material";
import { useAccountContext } from "./AccountContext";
import OrderHistorySection from "./OrderHistorySection";
import ProfileStatusSection from "./ProfileStatusSection";
import Card from "@mui/material/Card";

function SectionThree() {
  const theme = useTheme();
  const { profile } = useAccountContext();
  return (
    <Card
      sx={{
        // boxShadow:1,
        borderRadius: "30px",
        "& > div": {
          backgroundColor: "primary.main",
          width: "100%",
          flexGrow: 1,
          padding: "30px",
        },

        display: "flex",
        gap: "2px",
        flexDirection: "column",
        alignItems: "cetner",
        overflow: "hidden",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
          "& > div": {
            width: "50%",
          },
        },
      }}
    >
      <ProfileStatusSection />
      <OrderHistorySection />
    </Card>
  );
}

export default SectionThree;
