import { Stack } from "@mui/material";
import { WalletType, useUtilityContent } from "suregifts-component-lib";
import { PaymentButton } from "./PaymentButton";
import { SummaryTitle } from "./SummaryTitle";
import { VoucherPayIcon, WalletPayIcon } from "../../icons/Icons";

import { VoucherSummaryView } from "./VoucherSummaryView";
import { WalletSummaryCard } from "./WalletSummaryCard";

export const BillsPaymentMethodView = () => {
  const {
    form: { setValue, watch },
  } = useUtilityContent();
  const mdoel = watch();
  const voucherButtonClicked = () => {
    setValue("paymentMethodId", WalletType.Voucher);
  };
  const cashButtonClick = () => {
    setValue("paymentMethodId", WalletType.CashWallet);
  };
  return (
    <div>
      <SummaryTitle title={"Payment Method"} />
      <Stack direction={"row"} spacing={2}>
        <PaymentButton onClick={voucherButtonClicked} active={mdoel.paymentMethodId === WalletType.Voucher} icon={<VoucherPayIcon />} title={"Voucher"} />
        <PaymentButton onClick={cashButtonClick} active={mdoel.paymentMethodId === WalletType.CashWallet} icon={<WalletPayIcon />} title={"Wallet"} />
      </Stack>
      {mdoel.paymentMethodId === WalletType.Voucher ? <VoucherSummaryView /> : <WalletSummaryCard />}
    </div>
  );
};
