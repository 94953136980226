import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EventSection } from "./EventSection";
import { ContactModel, useGetContactById, useAddContact, useEditContact } from "../../../../services/contact";
const schema = yup
  .object<YupShape<ContactModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required("Field is required."),
  })
  .required();
function ContactAddEditScreen() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ContactModel>({
    defaultValues: {
      events: [],
    },
    resolver: yupResolver(schema),
  });
  const { contactId } = useParams();
  useGetContactById(contactId, (data) => {
    setValue("address", data.address);
    setValue("firstName", data.firstName);
    setValue("lastName", data.lastName);
    setValue("email", data.email);
    setValue("phone", data.phone);
  });
  const { mutate: addContact, isLoading } = useAddContact();
  const { mutate: editContact, isLoading: isEditing } = useEditContact(contactId);

  const submitForm = (model) => {
    if (contactId) {
      editContact(model, {
        onSuccess: () => {
          toast.success("Contact edited successfully.");
          navigate("/contacts");
        },
      });
    } else {
      addContact(model, {
        onSuccess: () => {
          toast.success("Contact added successfully.");
          navigate("/contacts");
        },
      });
    }
  };
  return (
    <>
      <Box sx={{ padding: "40px 50px", backgroundColor: "primary.main", borderRadius: "30px" }} component="form" onSubmit={handleSubmit(submitForm)}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ fontSize: "24px", fontWeight: 700, lineHeight: "29px" }}>{pathname === "/contacts/add" ? "Add" : "Edit"} Contact Information</Typography>
          <FormButton label="Save new contact" disabled={!isValid} loading={isLoading || isEditing} type="submit" />
        </div>
        <Stack spacing={3} sx={{ marginTop: "36px" }}>
          <Stack spacing={2} direction="row">
            <FormInput required label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
            <FormInput required label="Last Name" {...register("lastName")} helperText={errors.firstName?.message} />
          </Stack>
          <Stack spacing={2} direction="row">
            <FormInput required label="Email" {...register("email")} helperText={errors.firstName?.message} />
            <FormInput required label="Phone" {...register("phone")} helperText={errors.firstName?.message} />
          </Stack>
          <FormInput label="Address" {...register("address")} helperText={errors.firstName?.message} />
        </Stack>
        {!contactId && <EventSection setValue={setValue} watch={watch} />}
      </Box>
    </>
  );
}

export default ContactAddEditScreen;
