import React, { createContext, useContext, useReducer } from "react";
import { ContactData, EventModel } from "../../../services/contact";
import { useDocumentTitle } from "suregifts-component-lib";
export type ScreenContextValueProps = {
  state: ScreenState;
  dispatch: React.Dispatch<ActionProps>;
};
const ContactContext = createContext({} as ScreenContextValueProps);

export const useContactContext = () => {
  return useContext(ContactContext);
};

type ScreenState = {
  pageType: "Main" | "EditEvent" | "AddEvent" | "Events";
  contact?: ContactData;
  event?: EventModel;
};

type BaseActionProps = {
  type: "Main" | "AddEvent";
};
type EventsActionProps = {
  type: "Events";
  param: ContactData;
};
type EditActionProps = {
  type: "EditEvent";
  param: EventModel;
};
type ActionProps = BaseActionProps | EditActionProps | EventsActionProps;

const initialState: ScreenState = { pageType: "Main" };

const reducer = (state: ScreenState, action: ActionProps): ScreenState => {
  switch (action.type) {
    case "AddEvent":
      return { ...state, pageType: "AddEvent" };
    case "EditEvent":
      return { ...state, pageType: "EditEvent", event: action.param };
    case "Events":
      return { pageType: "Events", contact: action.param };
    default:
      return { pageType: "Main" };
  }
};

export const ContactContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useDocumentTitle("Contacts");
  return <ContactContext.Provider value={{ state, dispatch }}>{children}</ContactContext.Provider>;
};
