import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

export const BackButton = ({ to }) => {
  const navigate = useNavigate();
  return (
    <Button
      sx={{
        padding: "24px 20px",
        borderRadius: "14px",
        "& .MuiButton-startIcon": {
          margin: "0",
        },
        position: "absolute",
        top: "30px",
        left: "-30px",
        zIndex: 6,
      }}
      onClick={() => navigate(to)}
      startIcon={<ArrowBackIosNewIcon />}
    ></Button>
  );
};
