import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DeliveryTruckIcon } from "suregifts-component-lib";
import { PersonalizeButtons } from "./PersonalizeButtons";
import { CartStepView } from "./CartStepView";
import { AddItemModel } from "../../../services/cart";
import { CheckoutButtons } from "./CheckoutButtons";
import { DeliveryButtons } from "./DeliveryButtons";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { PencilIcon } from "../../../icons/Icons";
import { CartSmallGiftView } from "./CartSmallGiftView";
const steps = [
  { title: "Step 1", title2: "Personalize", icon: PencilIcon },
  { title: "Step 2", title2: "Delivery", icon: DeliveryTruckIcon },
  { title: "Step 3", title2: "Payment", icon: CreditCardIcon },
];
type CartJourneySectionProps = {
  activeStep: number;
  model?: AddItemModel;
};
export const CartJourneySection = ({ activeStep: step, model }: CartJourneySectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === "light" ? "#FFF" : "#000",
        borderRadius: "20px",
        border: 1,
        borderColor: "#404745",
        padding: "20px",
        backdropFilter: "blur(32.0082px)",
        display: "flex",
        flexWrap: "wrap",
        gap: "30px",
        justifyContent: "space-between",
        marginTop: "40px",
        "& .cart-steps": {
          display: "none ",
        },
        [theme.breakpoints.up("sm")]: {
          flexWrap: "nowrap !important",
        },
        [theme.breakpoints.up("md")]: {
          "& .cart-steps": {
            display: "block !important",
          },
        },
      }}
    >
      {!!model && <CartSmallGiftView model={model} />}
      <div className="cart-steps">
        <div style={{ display: "flex", gap: "50px" }}>
          {steps.map((item, index) => (
            <CartStepView key={index} index={index} step={step} {...item} />
          ))}
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          "& button.MuiButton-root": {
            whiteSpace: "nowrap",
          },
        }}
      >
        {step === 0 && <PersonalizeButtons />}
        {step === 1 && <DeliveryButtons />}
        {step === 2 && <CheckoutButtons />}
      </Box>
    </Box>
  );
};
