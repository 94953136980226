import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import CartScreen from "./CartScreen";
import { WalletInfoData, WalletType, formatMoney, useAuthContext, useDocumentTitle } from "suregifts-component-lib";
import numeral from "numeral";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { RequireAuth } from "../../../routes/RequireAuth";
import { CheckoutModel } from "../../../services/cart";
import { useGetWalletInfo } from "../../../services/transaction";
import { useNavigate } from "react-router-dom";
export type ScreenContextValueProps = {
  state: ScreenState;
  dispatch: React.Dispatch<ActionProps>;
  wallet?: WalletInfoData;
  model: CheckoutModel;
  updateModel: (params: any) => void;
  calcAmount: {
    amountDue: string | undefined;
    walletDeduction: string;
    outstanding: number;
  };
};
const CartContext = createContext({} as ScreenContextValueProps);

export const useCartContext = () => {
  return useContext(CartContext);
};

type ScreenState = {
  pageType: "Main";
};

type BaseActionProps = {
  pageType: "Main";
};

type ActionProps = BaseActionProps;

const initialState: ScreenState = { pageType: "Main" };

const reducer = (state: ScreenState, action: ActionProps): ScreenState => {
  switch (action.pageType) {
    default:
      return { pageType: "Main" };
  }
};

export const CartContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: wallet } = useGetWalletInfo();
  const { cart } = useGlobalContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  useDocumentTitle("Cart");
  const [model, setModel] = useState({ paymentMethod: WalletType.BankTransfer } as CheckoutModel);
  const calcAmount = useMemo(() => {
    if (model.useWallet) {
      let amountDue = numeral(cart?.amountDue).value() || 0;
      const walletDeduction = Math.min(amountDue, wallet?.balance || 0);
      const outstanding = amountDue - walletDeduction;
      if (outstanding <= 0) {
      }
      return {
        walletDeduction: `${user?.currency}${formatMoney(walletDeduction)}`,
        amountDue: `${user?.currency}${formatMoney(amountDue - walletDeduction)}`,
        outstanding,
      };
    }
    return {
      walletDeduction: `${user?.currency}0.00`,
      amountDue: cart?.amountDue,
      outstanding: numeral(cart?.amountDue).value() || 0,
    };
  }, [model.useWallet, cart?.amountDue]);

  return (
    <RequireAuth>
      <CartContext.Provider
        value={{
          state,
          dispatch,
          wallet,
          model,
          updateModel: (params) => {
            setModel({ ...model, ...params });
          },
          calcAmount,
        }}
      >
        <CartScreen />
      </CartContext.Provider>
    </RequireAuth>
  );
};
