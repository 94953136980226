import React, { useEffect } from "react";
import { DeliveryMediumType, TabPanel, formatMoney } from "suregifts-component-lib";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useGetGiftCardById } from "../../../../services/gift";
import { useCartDeliveryContext } from "../useCartDeliveryContext";
import "./style.css";
import { LeftBottom } from "./LeftBottom";
import { LeftTop } from "./LeftTop";
import { RightBottom } from "./RightBottom";
import { RightTop } from "./RightTop";
function PdfDeliveryPanel({}) {
  const { model, updateModel } = useCartDeliveryContext();
  useEffect(() => {
    updateModel({ isValid: true });
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "386px 386px",
        gridTemplateRows: "500px",
        borderRadius: "10px",
        overflow: "hidden",
        color: "#000000",
        "& > div": {
          backgroundColor: "white",
          height: "500px",
        },
      }}
    >
      <LeftTop />
      <RightTop />
      <LeftBottom />
      <RightBottom />
    </Box>
  );
}

export default PdfDeliveryPanel;
