import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FormButton, FormInput, FormSelect, PageHeaderTitle, YupShape, useAuthContext } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useAccountSettingContext } from "./AccountSettingContext";
import { useEffect, useMemo } from "react";
import { useUpdateProfile, useGetPhoneCountries, UpdateProfileModel, ProfileInfo } from "../../../services/auth";
import { useQueryClient } from "react-query";
const schema = yup
  .object<YupShape<UpdateProfileModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    phoneCountryCode: yup.string().required(),
  })
  .required();

export const ProfileInfoSection = () => {
  const { user: userInfo, updateInfo } = useAuthContext();
  const user = userInfo as ProfileInfo;
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdateProfileModel>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phoneNumber,
      phoneCountryCode: user?.phoneCountryCode || "",
      receiveEmailLetter: false,
      receiveSmsLetter: false,
    },
    resolver: yupResolver(schema),
  });
  const { profile } = useAccountSettingContext();
  useEffect(() => {
    setValue("receiveEmailLetter", profile?.receiveEmailLetter || false);
    setValue("receiveSmsLetter", profile?.receiveSmsLetter || false);
  }, [profile]);

  const { mutate: createPin, isLoading } = useUpdateProfile();
  const submitForm = (model) => {
    createPin(model, {
      onSuccess: (data) => {
        toast.success("Profile updated successfully.");
        updateInfo({ ...user, ...data });
        queryClient.invalidateQueries("profile");
      },
    });
  };
  const { data: countryNames } = useGetPhoneCountries();

  const countries = useMemo(() => {
    return countryNames?.map((x) => ({
      id: x.isoCode,
      name: x.name,
    }));
  }, [countryNames]);
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <PageHeaderTitle title="Profile Information" />
      <Stack spacing={3} sx={{ paddingTop: "30px" }}>
        <Stack spacing={2} direction="row">
          <FormInput required label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput required label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
        </Stack>
        <Stack spacing={2} direction="row">
          <Controller control={control} name="phoneCountryCode" render={({ field }) => <FormSelect required helperText={errors.phoneCountryCode?.message} {...field} label={"Country"} options={countries} disabled={user?.phoneNumberConfirmed} />} />
          <FormInput disabled={user?.phoneNumberConfirmed} isNumber value={watch("phone")} required label="Phone" {...register("phone")} helperText={errors.phone?.message} />
        </Stack>

        <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "17px", color: "text.secondary" }}>So that SureGifts may serve you Better, pls consent to receive:</Typography>
        <Stack direction={"row"} spacing={2}>
          <Controller
            control={control}
            name="receiveSmsLetter"
            render={({ field: { onChange, value } }) => <FormControlLabel sx={{}} checked={value} onChange={(e: any) => onChange(e.target.checked)} control={<Checkbox color="success" />} label="Receive SMS Notification" />}
          />
          <Controller
            control={control}
            name="receiveEmailLetter"
            render={({ field: { onChange, value } }) => <FormControlLabel sx={{}} checked={value} onChange={(e: any) => onChange(e.target.checked)} control={<Checkbox color="success" />} label="Receive Email Notification" />}
          />
        </Stack>
        <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "17px", color: "text.secondary" }}>When we send you an email or SMS, the informations not encrypted. This means a third party may be able to access the infromations</Typography>
        <FormButton label="Submit Changes" loading={isLoading} disabled={!(isValid && isDirty)} type="submit" sx={{ alignSelf: "flex-start" }} />
      </Stack>
    </form>
  );
};
