import { Typography } from "@mui/material";
import { ContactPhoneEmailView } from "../contact-support/LeftSection";
import { useGetIndustries } from "../../../services/general";

export const LeftSection = () => {
  return (
    <div>
      <Typography sx={{ fontSize: "6.25rem", fontWeight: 700, lineHeight: "110%" }}>Sign up as a merchant</Typography>
      <Typography sx={{ marginTop: "1.5rem" }}>Contact us if you need assitancre and we’ll get back to you in a jiffy 😀</Typography>
      <ContactPhoneEmailView />
      <Typography sx={{ fontSize: "1.25rem", fontWeight: 700, lineHeight: "1.5rem", marginTop: "3.375rem" }}>Who is a SureGifts Merchant?</Typography>
      <Typography sx={{ marginTop: "1.25rem", lineHeight: "1.75rem" }}>
        You are eligible to become a SureGifts Merchant if you are a business, store, organisation, retailer, product/service provider that already accepts payments via cards, cash or other means whether online or at physical business locations.
      </Typography>
      <Typography sx={{ marginTop: "1.25rem", lineHeight: "1.75rem" }}>By accepting gift cards at your business, you create an ecosystem where people can send your product/service directly to family, friends and loved ones as GIFTS.</Typography>
    </div>
  );
};
